import PulsesImportParityForm from "./import_parity/pulses/PulsesImportParityForm";
import WheatImportParityForm from "./import_parity/wheat/WheatImportParityForm";
import SoybeanParityForm from "./soybean_parity/SoybeanParityForm";
import CreateCrushMarginForm from "./crush_margin/CreateCrushMarginForm";


function FormMapper({ sectionObj }){

    if (sectionObj['section_code'] == 'wheat_daily_import_parity'){
        return <WheatImportParityForm sectionObj={sectionObj} />
    } else if (['tur_daily_import_parity', 'urad_daily_import_parity', 'moong_daily_import_parity', 'chana_daily_import_parity', 'masoor_daily_import_parity','pea_daily_import_parity'].includes(sectionObj['section_code'])){
        return <PulsesImportParityForm sectionObj={sectionObj} />
    } else if(sectionObj['section_code'] == 'soybean_daily_parity') {
        return <SoybeanParityForm sectionObj={sectionObj} />
    } else if(['soybean_daily_crush_margin', 'rapeseed_daily_crush_margin'].includes(sectionObj['section_code'])){
        return <CreateCrushMarginForm sectionId={sectionObj['section_id']}/>
    }
    else {
        return <></>;
    }
}

export default FormMapper;