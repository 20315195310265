

export function getSearchParams(location) {
    const dict = {}
    try {
        const searchQuery = location['search']
        const queryString = searchQuery.split('?')
        const paramList = queryString[1].split('&')
        for (let i = 0; i < paramList.length; i++) {
            const t = paramList[i].split('=')
            dict[t[0]] = t[1]
        }
    } catch (err) {
        //
    }
    return dict
}