import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import DraftEditor from "components/input/raw_inputs/draft_editor/DraftEditor";
import { EditorState, convertToRaw } from 'draft-js';

function emptyState() {
    let editorState = EditorState.createEmpty();
    let contentState = editorState.getCurrentContent();
    let rawState = convertToRaw(contentState);
    return rawState;
}

function ReportContentEditor({ reportId, defaultContent = {} }) {

    const [defaultContentState, setDefaultContentState] = useState(() => emptyState());

    useEffect(() => {
        if (defaultContent == null || Object.keys(defaultContent).length == 0){
            setDefaultContentState(() => emptyState());
        } else {
            setDefaultContentState(defaultContent);
        }
    }, [reportId, defaultContent])

    const updateDraft = (draftContent) => {
        if (reportId == null || reportId == 0 || reportId == ''){
            alert('No Report Found');
            return;
        }
        const authToken = localStorage.getItem('token');
        let requestBody = {
            content: draftContent,
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/${reportId}/content`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: authToken,
            },
            body: JSON.stringify(requestBody),
        })
            .then(r => r.json())
            .then(res => {
                if (res.success == true) {
                    alert(res.data);
                } else {
                    alert(JSON.stringify(res.data));
                }
            });
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                Enter Draft Details:{" "}
            </Grid>
            <Grid item xs={12}>
                <DraftEditor
                    submit={updateDraft}
                    defaultContent={defaultContentState}
                />
            </Grid>
        </Grid>
    );
}

export default ReportContentEditor;
