import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';

function ImportSectionDataModal({ sectionId, modalStatus, handleClose, sectionUpdateFlag }) {


    useEffect(() => {
        setSourceSectionId(0);
        setSourceSection({});
    }, [sectionUpdateFlag]);

    const [sourceSectionId, setSourceSectionId] = useState(0);
    const [sourceSection, setSourceSection] = useState({});

    const fetchData = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sourceSectionId}`, {
            method: 'GET',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }
        })
            .then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    setSourceSection(r.data);
                } else {
                    alert('Error Fetch Section Object');
                }
            });
    };

    const copyData = () => {
        const token = localStorage.getItem('token');
        alert(`Source Section Id : ${sourceSectionId} :: targetId : ${sectionId}`);
        if (sourceSectionId <= 0){
            alert('Mention correct section id');
            return;
        }
        const formData = new FormData();
        formData.append('source_section', sourceSectionId);
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/duplicate`,{
            method:'POST',
            headers:{
                Authorization: token
            },
            body:formData
        }).then(r => r.json())
        .then(r => {
            if (r.success == true){
                alert(r.data);
            } else {
                alert(r.data);
            }
        })
    }

    return (
        <Modal show={modalStatus} onHide={handleClose} size='lg'>
            <Modal.Header>
                <h3>Import Data</h3>
            </Modal.Header>
            <Modal.Body>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextFieldInput
                            isRequired={true}
                            label='Target Section Id'
                            currentValue={sourceSectionId}
                            handleInput={setSourceSectionId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={() => fetchData()}> Fetch Data </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <p>Source Section Id : {sourceSection.section_id}</p>
                            <p>Source Section Report: {sourceSection.report_type}</p>
                            <p>Source Section Date: {sourceSection.publish_date}</p>
                            <p>Source Section Name: {sourceSection.section_name}</p>
                            <p>Source Section Code: {sourceSection.section_code}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() => copyData()}>
                            Replicate Data
                        </Button>
                    </Grid>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ float: 'right' }}>
                    <Button variant="contained" onClick={() => handleClose()}>Close Modal</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default ImportSectionDataModal;