import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SelectInput from "components/input/raw_inputs/SelectInput";
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';

function EditPersonalInfo({ clientId }){

    const [name, setName] = useState('');
    const [phone, setPhone] = useState(null);
    const [organization, setOrganization] = useState('');
    const [status, setStatus] = useState('');
    const [saveFlag, setSavedFlag] = useState(false);

    useEffect(() => {
        if(saveFlag == true){
            setSavedFlag(false);
        }
    }, [name, phone, organization, status]);

    const fetchCliendDetails = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/client/${clientId}`,{
            method:'GET',
            headers:{
                Authorization:token
            },
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                setName(r['data']['name']);
                setPhone(r['data']['phone']);
                setOrganization(r['data']['organization']);
                setStatus(r['data']['status']);
                setSavedFlag(false);
            } else {
                alert('Error Fetching Client');
            }
        });
    }

    useEffect(() => {
        fetchCliendDetails();
    }, [clientId]);

    const submit = () => {
        const formData = new FormData();
        if (name !== '') formData.append('name', name);
        if (phone !== null) formData.append('phone', phone);
        if (organization !== '') formData.append('organization', organization);
        if (status !== '') formData.append('approval_status', status);
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/client/${clientId}`,{
            method:'PUT',
            headers:{
                Authorization:token
            },
            body:formData
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert('Client Updated successfully');
                setSavedFlag(true);
            } else {
                alert('Error Updateing Client');
            }
        })
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h3> Edit Client Personal Info </h3>
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={true} label='Name' currentValue={name} handleInput={setName} />
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={false} label='Contact Number' currentValue={phone} handleInput={setPhone} />
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={true} label='Organization' currentValue={organization} handleInput={setOrganization} />
            </Grid>
            <Grid item xs={6}>
                <SelectInput 
                    label='status'
                    optionList={[{label:'', value:''},{label:'Approved', value:'approved'}, {label:'Pending', value:'pending'}, {label:'Deleted', value:'deleted'}]}
                    handleInput={setStatus}
                    value={status}
                />
            </Grid>
            <Grid item xs={6}>
                <Button variant='contained' onClick={submit} > Save Client </Button>
            </Grid>
            <Grid item xs={6}>
                <div style={{float:'right'}}>
                    {(saveFlag) ? 'Saved' : 'Pending'}
                </div>
            </Grid>
        </Grid>
    )

}

export default EditPersonalInfo;