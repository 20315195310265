import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DenseTable from './DenseTable';
import './dense_table.css';
import Grid from '@mui/material/Grid';

const defaulCoulumns = ['name', 'age', 'city', 'country'];

const defaultRows = [
    { name: 'Tushar', age: 28, city: 'jal', country: 'india' },
    { name: 'Akash', age: 30, city: 'LA', country: 'USA' },
    { name: 'Deepak', age: 38, city: 'Jind', country: 'India' },
    { name: 'Tushar2', age: 28, city: 'jal', country: 'india' },
    { name: 'Tushar3', age: 28, city: 'jal', country: 'india' },
    { name: 'Akash3', age: 30, city: 'LA', country: 'USA' },
    { name: 'Deepak4', age: 38, city: 'Jind', country: 'India' }
];

function DynamicDenseTable({ columnHeaders=defaulCoulumns, columnRows =defaultRows, breakPointListStr="100" }) {

    const [rowList, setRowList] = useState([columnRows]);

    useEffect(() => {
        const numRows = columnRows.length;
        const breakPointList = breakPointListStr.split(",").map(s=>s.trim()).map(Number);
        let bp = 1;
        let startPoint = 0;
        const tempRowList = [];
        for(let i=0;i<=breakPointList.length;i++){
            if (i == breakPointList.length){
                bp = numRows;
            } else {
                bp = breakPointList[i]; 
            }         
            let section = columnRows.slice(startPoint, bp);
            tempRowList.push(section);
            if (bp >= numRows){
                break;
            }
            startPoint = bp;
        }
        setRowList(tempRowList);
    }, [columnHeaders, columnRows, breakPointListStr]);

    return (
        <Grid container spacing={2}>
            {rowList.map((rowCollections, index) => {
                return (
                    <Grid item xs={12} key={uuidv4()}>
                        <DenseTable columnHeaders={columnHeaders} columnRows={rowCollections} />
                        {(index < (rowList.length-1))? <div className="pagebreak" /> :<></>}
                    </Grid>
                )
            })}
            
        </Grid>
    )

}

export default DynamicDenseTable;