import EditClientInfo from "./edit_client_info/EditClientInfo";
import ClientEmailLogs from "./client_email_logs/ClientEmailLogs";
import ClientSubscriptionForm from "../create_client/ClientSubscriptionForm";

function EditClientMapper({ operation, clientDetails }){
    if(operation=='editPersonal'){
        return <EditClientInfo clientDetails={clientDetails} />
    } else if(operation=='editSubscription'){
        return <ClientSubscriptionForm clientId={clientDetails['id']} />
    } else if(operation=='openEmailHistory'){
        return <ClientEmailLogs clientId={clientDetails['id']} />
    }
    else {
        return <></>
    }

}

export default EditClientMapper;