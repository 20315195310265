import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import DataField from '../../headerFooterBox/DataField';

function HeaderFooterSection({ sectionId, showModal, handleClose }) {

    const [response, setResponse] = useState({
        'upload_excel_section_heading': '',
        'upload_excel_section_footer': '',
        'upload_image_section_heading': '',
        'upload_image_section_footer': '',
        'visual_table_section_heading': '',
        'visual_table_section_footer': '',
        'visual_chart_section_heading': '',
        'visual_chart_section_footer': ''
    })

    useEffect(() => {
        fetchData();
    }, [sectionId]);

    const fetchData = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}/metacontent`, {
            method: 'GET',
            headers: {
                Authorization: token
            },
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    setResponse(r.data);
                } else {
                    alert('Unable to update value');
                }
            })
    }

    return (
        <Modal show={showModal} onHide={handleClose} >
            <Modal.Header>
                Header Footer Preview
            </Modal.Header>
            <Modal.Body>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DataField sectionId={sectionId} label='Edit Header' defaultValue={response['visual_table_section_heading']} type='header' field={'vis_table'} />
                    </Grid>
                    <Grid item xs={12}>
                        <DataField sectionId={sectionId} label='Edit Footer' defaultValue={response['visual_table_section_footer']} type='footer' field={'vis_table'} />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider> <h4> FOR IMAGE </h4></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <DataField sectionId={sectionId} label='Edit Header' defaultValue={response['visual_chart_section_heading']} type='header' field={'vis_chart'} />
                    </Grid>
                    <Grid item xs={12}>
                        <DataField sectionId={sectionId} label='Edit Footer' defaultValue={response['visual_chart_section_footer']} type='footer' field={'vis_chart'} />
                    </Grid>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="contained" onClick={() => handleClose()} color="success">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>

    )
}

export default HeaderFooterSection;