import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// const sampleRepsonse = {
//     'date':{current:'8-Jan-2024', previous:'5-Jan-2024'},
//     'seed_price':{current:48250, previous:47350, change:2},
//     'crush_expenses':{current:1350, previous:1350, change:2},
//     'qualit_tolerance':{current:100, previous:120, change:2},
//     'cash_discount':{current:147, previous:157, change:2},
//     'total_cost':{current:48635, previous:47753, change:2},
//     'oil_price':{current:830, previous:830, change:2},
//     'oil_share':{current:14940, previous:14952, change:2},
//     'meal_price':{current:40810, previous:41510, change:2},
//     'meal_share':{current:33464, previous:87456, change:2},
//     'total_realization':{current:48404, previous:48978, change:-1},
//     'net_crush_margin':{current:-231, previous:1225, change:-119}
// }

const sampleRepsonse =  {
    "cash_discount": {
        "change": -1.0,
        "current": -566.5,
        "previous": -565.5
    },
    "claim": {
        "change": 0.0,
        "current": 0.0,
        "previous": 0.0
    },
    "crush_expenses": {
        "change": 0.0,
        "current": 1250.0,
        "previous": 1250.0
    },
    "date": {
        "current": "2023-07-20",
        "previous": "2023-07-19"
    },
    "meal_price": {
        "change": 5.0,
        "current": 22010.0,
        "previous": 22005.0
    },
    "meal_share": {
        "change": 3.1,
        "current": 13866.3,
        "previous": 13863.2
    },
    "net_crush_margin": {
        "change": 95.1,
        "current": 4562.33,
        "previous": 4467.23
    },
    "oil_price": {
        "change": 0.0,
        "current": 1040.0,
        "previous": 1040.0
    },
    "oil_share": {
        "change": 0.0,
        "current": 38480.0,
        "previous": 38480.0
    },
    "quality_tolerance": {
        "change": -0.75,
        "current": -424.875,
        "previous": -424.125
    },
    "seed_price": {
        "change": 100.0,
        "current": 56650.0,
        "previous": 56550.0
    },
    "total_cost": {
        "change": 98.2,
        "current": 56908.6,
        "previous": 56810.4
    },
    "total_realization": {
        "change": 3.2,
        "current": 52346.3,
        "previous": 52343.1
    }
}

function RapeseedCrushMarginTable({ dataObject=sampleRepsonse }){

    return (
        <TableContainer component={Paper} className='tableContainer' sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                <TableRow className='table-header-tg'>
                        <TableCell ><b>Rapeseed Crush Parity</b></TableCell>
                        <TableCell><b>{dataObject['date']['current']}</b></TableCell>
                        <TableCell><b>{dataObject['date']['previous']}</b></TableCell>
                        <TableCell><b>Change</b></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Rapeseed Price Ex. Jaipur (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['seed_price']['current']}</TableCell>
                        <TableCell>{dataObject['seed_price']['previous']}</TableCell>
                        <TableCell>{dataObject['seed_price']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Crush Expenses (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['crush_expenses']['current']}</TableCell>
                        <TableCell>{dataObject['crush_expenses']['previous']}</TableCell>
                        <TableCell>{dataObject['crush_expenses']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Quality Tolerance</TableCell>
                        <TableCell>{dataObject['quality_tolerance']['current']}</TableCell>
                        <TableCell>{dataObject['quality_tolerance']['previous']}</TableCell>
                        <TableCell>{dataObject['quality_tolerance']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Cash Discount</TableCell>
                        <TableCell>{dataObject['cash_discount']['current']}</TableCell>
                        <TableCell>{dataObject['cash_discount']['previous']}</TableCell>
                        <TableCell>{dataObject['cash_discount']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Total Cost (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['total_cost']['current']}</TableCell>
                        <TableCell>{dataObject['total_cost']['previous']}</TableCell>
                        <TableCell>{dataObject['total_cost']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Oil Price Ex. Jaipur (Rs./Tonne) Excl GST</TableCell>
                        <TableCell>{dataObject['oil_price']['current']}</TableCell>
                        <TableCell>{dataObject['oil_price']['previous']}</TableCell>
                        <TableCell>{dataObject['oil_price']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Realization Oil Share (37%)</TableCell>
                        <TableCell>{dataObject['oil_share']['current']}</TableCell>
                        <TableCell>{dataObject['oil_share']['previous']}</TableCell>
                        <TableCell>{dataObject['oil_share']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Oilcake Price Ex Jaipur (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['meal_price']['current']}</TableCell>
                        <TableCell>{dataObject['meal_price']['previous']}</TableCell>
                        <TableCell>{dataObject['meal_price']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Realization Meal Share (63%)</TableCell>
                        <TableCell>{dataObject['meal_share']['current']}</TableCell>
                        <TableCell>{dataObject['meal_share']['previous']}</TableCell>
                        <TableCell>{dataObject['meal_share']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total Realization (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['total_realization']['current']}</TableCell>
                        <TableCell>{dataObject['total_realization']['previous']}</TableCell>
                        <TableCell>{dataObject['total_realization']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Net Crush Margin (Rs./Tonne)</TableCell>
                        <TableCell>{dataObject['net_crush_margin']['current']}</TableCell>
                        <TableCell>{dataObject['net_crush_margin']['previous']}</TableCell>
                        <TableCell>{dataObject['net_crush_margin']['change']}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default RapeseedCrushMarginTable;