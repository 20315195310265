import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';

function AddLabelComponentTwo({ addLabel }){

    const [inputText, setInputText] = useState('');

    const add = () => {
        if (inputText !== null && inputText !== ''){
            addLabel(inputText);
            setInputText('');
        } 
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextFieldInput label='New Section Name' currentValue={inputText} handleInput={setInputText} />
            </Grid>
            <Grid item xs={12}>
                <Button onClick={add}>Add Label</Button>
            </Grid>
        </Grid>
    )
}

export default AddLabelComponentTwo;