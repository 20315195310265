import { Modal, } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import WheatImportParityLatest from "components/tables/calculation_table/import_parity/WheatImportParityLatest";

function WheatImportParityModal({ modalStatus, handleClose, dataObject }) {

    
    return (
        <Modal show={modalStatus} onHide={handleClose} size='lg'>
            <Modal.Header>
                <h3>Wheat Import Parity</h3>
            </Modal.Header>
            <Modal.Body>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <WheatImportParityLatest dataObject={dataObject} />
                    </Grid>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ float: 'right' }}>
                    <Button variant="contained" onClick={() => handleClose()} color="success">
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default WheatImportParityModal;