import { useState } from 'react';
// import { v4 as uuidv4 } from "uuid";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import UploadFileButton from 'components/input/raw_inputs/UploadFileButton';

function UploadReportSection({ reportId }) {

    const [file, setFile] = useState(null);
    const [resetKey, setResetKey] = useState(0);

    const changeResetKey = () => {
        const t = resetKey;
        setResetKey(t+1);
    }

    const setFileFunc = (file) => {
        setFile(file);
    }

    const uploadReport = () => {
        if(file == null){
            alert('Add File first');
            return;
        }
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/${reportId}/upload`, {
            method:'POST',
            headers:{
                Authorization:token,
            },
            body:formData
        }).then(r => r.json())
        .then(res => {
            if(res.success==true){
                alert('File Uploaded')
                
            } else{
                alert(JSON.stringify(res));
            }
        });
        setFile(null);
        changeResetKey();
    }

    const downloadReport = () => {
        const authToken = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_URL}/report/${reportId}/download`
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: authToken
            }
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    window.open(r.data);
                } else {
                    alert(r.data);
                }
            })
    }

    const deleteReport = () => {
        const authToken = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_URL}/report/${reportId}/file`
        fetch(url, {
            method: 'DELETE',
            headers: {
                Authorization: authToken
            }
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    alert('Report File Delete successfully');
                } else {
                    alert(r.data);
                }
            })
        changeResetKey();
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={5}>
               <UploadFileButton label={''} setFile={setFileFunc} resetKey={resetKey} />
            </Grid>
            <Grid item xs={7}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Button variant='contained' onClick={() => uploadReport()} color='success'>Upload Report</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant='contained' onClick={() => downloadReport()}>Download</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant='contained' onClick={() => deleteReport()}>Delete Report</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UploadReportSection;