import { useState } from 'react';
import Grid from '@mui/material/Grid';
import ClientPersonalInfoForm from './ClientPersonalInfoForm';
import ClientSubscriptionForm from './ClientSubscriptionForm';
import Divider from '@mui/material/Divider';

function NewClient(){

    const [clientId, setClientId] = useState(0);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Divider>
                    <h3>Create New Client</h3>
                </Divider>
            </Grid>
            <Grid item xs={4}>
                <ClientPersonalInfoForm
                    setClientId={setClientId}
                 />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={6}>
                <ClientSubscriptionForm clientId={clientId} />
            </Grid>
        </Grid>
    )
}

export default NewClient;