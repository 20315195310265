
import UploadReportSection from "./physical_report/UploadReportSection";
// import ReportContextSection from "./digital_report/ReportContextSection";
import EditReportFactors from 'dashboard/reports/common/set_report_factors/EditReportFactors';

function ReportMediumMapper({ reportId, medium }){

    if (reportId != null){
        if (medium == 'physical'){
            return <UploadReportSection reportId={reportId} />
        } else if (medium == 'digital'){
            return <EditReportFactors reportId={reportId} />
        }
    }
    return <></>;
}

export default ReportMediumMapper;