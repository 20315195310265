import React from 'react';
import Grid from '@mui/material/Grid';
import { Image } from 'react-bootstrap';
import Logo from 'resources/image/logo.png';
import './reportheader.css';


function ReportHeader({ reportType='TEST REPORT', publishDate='1970-1-1'}) {
        const formattedDate = new Date(publishDate);
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDateString = formattedDate.toLocaleDateString('en-GB', options);

        return (
            <Grid container spacing={0.5}>
                <Grid item xs={6}>
                    <div >
                        <Grid container spacing={0.2}>
                            <Grid item xs={12}> 
                                {(reportType === 'Wheat Daily Digest' || reportType === 'Rice Daily Digest' || reportType === 'Maize Daily Digest') && <h4>Grains</h4>} 
                                {reportType === 'Pulses Daily Digest' && <h4>Pulses</h4>} 
                                {reportType === 'Coriander Daily Digest' && <h4>Spices</h4>} 
                                {reportType === 'Soybeans Daily Digest' || reportType === 'Rapeseed Daily Digest'  && <h4>Oilseeds</h4>} 
                                <h3>{reportType}</h3> 
                            </Grid>
                            <Grid item xs={12}>
                                <h4>{formattedDateString}</h4>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid  item xs={6} style={{ textAlign: 'right' }}>
                    <Image src={Logo} className="header-image"/>
                </Grid>
            </Grid>
        );
}
export default ReportHeader;