import { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from "@mui/material";

function ApprovalBtn({ sectionId, visualizationType, approvalStatus, sectionUpdateFlag }) {

    const [checked, setChecked] = useState(() => (approvalStatus == 'approved') ? true : false);

    useEffect(() => {
        setChecked(() => (approvalStatus == 'approved') ? true : false);
    }, [sectionUpdateFlag]);

    const handleChange = (event) => {
        if (event.target.checked == 1) {
            updateDraft("approved");
        } else {
            updateDraft("pending");
        }
    };

    const updateDraft = (approvalStatus) => {
        const authToken = localStorage.getItem('token');
        let requestBody = {};
        if (visualizationType == 'vis_chart') {
            requestBody = { visual_chart_approval_status: approvalStatus, };
        } else if (visualizationType == 'vis_table') {
            requestBody = { visual_table_approval_status: approvalStatus, }
        }
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: authToken,
            },
            body: JSON.stringify(requestBody),
        })
            .then((r) => r.json())
            .then((res) => {
                if (res.success == true) {
                    alert(`Visualization Status updated to ${approvalStatus}`);
                    if (approvalStatus == 'approved') {
                        setChecked(true);
                    } else {
                        setChecked(false);
                    }
                } else {
                    alert(JSON.stringify(res.data));
                }
            });
    };

    return (
        <div>
            <FormControlLabel
                value={checked}
                control={<Checkbox checked={checked} onChange={handleChange} />}
                label="Approve Visualization"
                labelPlacement="end"
            />
        </div>
    )
}


export default ApprovalBtn;