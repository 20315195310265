/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';

const defaultOptionList = [
  { label: "India", value: "India" },
  { label: "China", value: "China" },
  { label: "Bangaldesh", value: "Bangladesh" },
  { label: "USA", value: "America" },
  { label: "Russia", value: "Roos" },
  { label: "UK", value: "England" },
  { label: "France", value: "Frnace" },
];

function SelectInput({label = "Default Label", optionList=defaultOptionList, helperText = "With label + helper text", value='', handleInput=()=>{}}) {

  const handleChange = (event) => {
    handleInput(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label="Age"
        onChange={handleChange}
      >
        {optionList.map((option) => {
          return (
            <MenuItem value={option["value"]} key={uuidv4()}>
              {option["label"]}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default SelectInput;
