import Grid from '@mui/material/Grid';
// import cmeWeeklyJson from './spot_price/weeklyTable.json';
import TableMapper from 'components/tables/TableMapper';

export default function CmeFuturesWeeklyTable(){
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h3>Weekly Table</h3>
            </Grid>
            <Grid item xs={6}>
                <TableMapper type='wheat_daily_summary' />
            </Grid>
            <Grid item xs={6}>
            <TableMapper type='pulses_daily_summary' />
            </Grid>
            <Grid item xs={6}>
            <TableMapper type='maize_daily_summary' />
            </Grid>
            <Grid item xs={6}>
            <TableMapper type='rice_daily_summary' />
            </Grid>
        </Grid>
    )
    
}