import ChartPreview from 'components/charts/ChartPreview';

function SectionVisualChart({ sectionObj }){

    if (sectionObj!= null && Object.keys(sectionObj).length > 0 && sectionObj['vis_chart_content']['approval_status'] == 'approved'){
        return (
            <div>
                <ChartPreview dataObject={sectionObj['vis_chart_content']} />
                {sectionObj['vis_chart_content']['chart_page_break'] ? <div className="pagebreak" /> : <></>}
            </div>
            )
    } else {
        return <></>;
    }
}

export default SectionVisualChart;