import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ClientFilter from "./ClientFilter";
import ClientTable from "./ClientTable";
import Divider from "@mui/material/Divider";

function EditClient(){

    const [filter, setFilter] = useState({
        name: '',
        email: '',
        organization: '',
        status: '',
        page_number: '',
    });
    const [response, setResponse] = useState({'max_page_count':10, 'client_data':[]});

    const fetchClient = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/client/list?name=${filter.name}&email=${filter.email}&organization=${filter.organization}&status=${filter.status}&page_number=${filter.page_number}`, {
            method:'GET',
            headers:{
                Authorization:token
            }
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                setResponse(r.data);
            } else {
                alert('Unable to fetch Clients');
            }
        })
    }

    useEffect(() => {
        fetchClient();
    }, [filter]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Divider>
                    <h3>Review Clients</h3>
                </Divider>
            </Grid>
            <Grid item xs={3}>
                <ClientFilter submit={setFilter} maxPageCount={response['max_page_count']} />
            </Grid>
            <Grid item xs={9}>
                <ClientTable clientList={response['client_data']} reset={fetchClient} />
            </Grid>
        </Grid>
    )
}

export default EditClient