import { Bar } from "react-chartjs-2";
import React, { useState } from 'react';
import "./barchart.css";

function getOptions(chartTitle, xAxisLabel, yAxisLabel){
    const opt = {
        plugins:{
            title:{
                display:true,
                text: chartTitle,
                font:{
                    size:12,
                    weight:'bold'
                }
            }
        },
        scales: {
          y: {
            beginAtZero: false,
            title:{
                display:true,
                text:yAxisLabel,
                font:{
                    size:10,
                    weight:'bold'
                }
            },
            grid:{
                display:false,
            }
          },
          x:{
            title:{
                display:true,
                text:xAxisLabel,
                font:{
                    size:12,
                    weight:'bold'
                }
            },
            grid:{
                display:false
            }
          }
        },
      };
      return opt;
}

const sampleMetaData = {
    chart_title:'Sample Title',
    x_axis_label:'Month Range',
    y_axis_label:'Quantity Values'
}

function BarLineComboPlot({ dataObject = {}, metadata=sampleMetaData}){

    const [transformedObject,] = useState(() => {
        const plotDataObject = {
            labels:[],
            datasets:[]
        }
        const keys = Object.keys(dataObject);
        let labels = []
        if (keys.length > 0){
            labels = dataObject[keys[0]].map(t => t['label']);
            plotDataObject['labels'] = labels;
            const dSets = keys.map(key => {
                const obj = {
                    label:key,
                    data: dataObject[key].map(t => t['value']),
                    borderWidth:2
                }
                obj['type'] = 'bar'
                if(['avg', 'max', 'min'].includes(key)){
                    obj['type'] = 'line'
                }
                return obj
            });
            plotDataObject['datasets'] = dSets;
        }
        return plotDataObject;
    })

    const [options, ] = useState(() => {
        const opt = getOptions(metadata['chart_title'], metadata['x_axis_label'], metadata['y_axis_label']);
        return opt
    })

    return (
        <div className='bar-chart-container'>
          <Bar data={transformedObject} options={options} />
        </div>
    );
    
    
}

export default BarLineComboPlot;