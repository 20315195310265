import { useState } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import PulsesImportParityModal from './PulsesImportParityModal';
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';
import SelectInput from 'components/input/raw_inputs/SelectInput';
import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";

function PulsesImportParityForm({ sectionObj }) {

    const [cnfUSDMT, setCnfUSDMT] = useState(0);
    const [commodityName, setCommodityName] = useState('');
    const [exchangeRate, setExchangeRate] = useState(80);
    const [domesticSpotPrice, setDomesticSpotPrice] = useState(0);
    const [modalStatus, setModalStatus] = useState(false);
    const [parityTable, setParityTable] = useState({});

    const getCommodityOptionList = () => {
        const commodityList = ['Tur', 'Moong', 'Masoor', 'Bengal Gram', 'Urad', 'Pea']
        const optionList = commodityList.map(t => {
            const option = { label: t, value: t };
            return option;
        });
        return optionList;
    }

    const submitForm = () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('commodity_name', commodityName);
        formData.append('date',  moment(sectionObj['publish_date']).format('YYYY-MM-DD'))
        formData.append('trade_source', 'International');
        formData.append('cnf_usdmt', cnfUSDMT);
        formData.append('import_duty_rate', 0);
        formData.append('cess_rate', 0);
        formData.append('exchange_rate', exchangeRate);
        formData.append('port_expenses', 2500);
        formData.append('brokerage', 300)
        formData.append('port_to_mill_freight_charges', 0);
        formData.append('premium_rate', 0);
        formData.append('domestic_spot_price', domesticSpotPrice);
        formData.append('domestic_freight_charge', 0);
        formData.append('domestic_clearing_charges', 0);
        fetch(`${process.env.REACT_APP_SERVER_URL}/calculations/importparity/pulses`, {
            method: 'POST',
            headers: {
                Authorization: token
            },
            body: formData
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    alert('parity record created');
                } else {
                    alert(r.data);
                }
            })
    }

    const clearForm = () => {
        setCommodityName('');
        setCnfUSDMT(0);
        setDomesticSpotPrice(0);
        setExchangeRate(80);
    }

    const preview = () => {
        const token = localStorage.getItem('token');
        const dt = moment(sectionObj['publish_date']).format('YYYY-MM-DD');
        const url = `${process.env.REACT_APP_SERVER_URL}/calculations/importparity/commodity?commodity_name=${commodityName}&report_date=${dt}`
        fetch(url, {
            method:'GET',
            headers:{
                Authorization:token
            }
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                setParityTable(r.data);
                setModalStatus(true);
            } else {
                alert(r.data);
            }
        });
    }

    const handleClose = () => {
        setModalStatus(false);
    };

    const deleteParity = () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('commodity_name', commodityName)
        formData.append('report_date', moment(sectionObj['publish_date']).format('YYYY-MM-DD'));
        fetch(`${process.env.REACT_APP_SERVER_URL}/calculations/importparity/`, {
            method:'DELETE',
            headers:{
                Authorization:token
            },
            body:formData
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert('parity record deleted');
            } else {
                alert(r.data);
            }
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h3> Pulses Import Parity Form</h3>
            </Grid>
            <Grid item xs={6}>
                <SelectInput
                    label='Commodity'
                    value={commodityName}
                    handleInput={setCommodityName}
                    optionList={getCommodityOptionList()}
                />
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={true} label='CNF Price (USDMT)' currentValue={cnfUSDMT} handleInput={setCnfUSDMT} />
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput label='USD/INR Rate' currentValue={exchangeRate} handleInput={setExchangeRate} />
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput label='Domestic Spot Price' currentValue={domesticSpotPrice} handleInput={setDomesticSpotPrice} />
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons label1='submit' label2='clear' action1={submitForm} action2={clearForm} />
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons label1='preview' label2='delete' action1={preview} action2={deleteParity} />
            </Grid>
            <PulsesImportParityModal modalStatus={modalStatus} handleClose={handleClose} dataObject={parityTable} />
        </Grid>
    )
}

export default PulsesImportParityForm;