import Grid from '@mui/material/Grid';
import TableMapper from './TableMapper';

function TablePreview( { dataObject, editFlag=true, sectionId=0, tableType='vis_table', breakPoint='100' }){

    if (dataObject !== null && Object.keys(dataObject).length > 0 && dataObject['data_present'] == true){
        return (
            <Grid container spacing={0.2}>
                <Grid item xs={12}>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px',fontWeight:'Bold', marginBottom:'0rem!important'}}>
                        {dataObject['visualization_header']}
                    </p>
                </Grid>
                <Grid item xs={12} >
                    <TableMapper
                        type={dataObject['visualization_data']['type']}
                        info={dataObject['visualization_data']['info']}
                        breakPoint = {breakPoint}
                        editFlag={editFlag}
                        sectionId={sectionId}
                        tableType={tableType}
                    />
                </Grid>
                <Grid item xs={12}>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', marginBottom:'0rem!important'}}>{dataObject['visualization_footer']}</p>
                </Grid>
            </Grid>
        )
    } else {
        return <></>
    }
    
}

export default TablePreview;