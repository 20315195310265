import PreviewPhysicalReport from "./physical_report_preview/PreviewPhysicalReport";
import ReportPreview from "./digital_report_preview/ReportPreview";

function ReportPreviewMapper({ medium, reportId }){

    if (medium == 'physical') {
        return <PreviewPhysicalReport reportId={reportId} />
    } else if (medium == 'digital') {
        return <ReportPreview reportId={reportId} />
    } else {
        return <></>;
    }

}

export default ReportPreviewMapper;