import React, { useContext } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { AuthContext } from 'auth/AuthContext';
// import PrimarySidebar from 'dashboard/sections/sidebar/PrimarySidebar';

import './header.css';

function Header({ toggleSidebar }) {

    const { logout } = useContext(AuthContext);

    const logoutFunc = () => {
        logout();
    }

    return (
        <Container fluid className='header_container'>
            <Navbar expand="sm" className='header_navbar'>
                <Navbar.Brand><Button onClick={() => toggleSidebar()}> Toggle </Button></Navbar.Brand>
                <Navbar.Brand className='navbar_brand'> Agalabs Admin Dashboard </Navbar.Brand>
                <Navbar.Brand className='LogoutBtn' onClick={() => logoutFunc()}>Logout</Navbar.Brand>
            </Navbar>
        </Container>
    );
}

export default Header;
