import ImagePreview from 'components/ImagePreview';

function UserInputImage({ sectionObj }){
    // const makeImageUrl = (imageUrl) => {
    //     const url = imageUrl.split("?")[0];
    //     return url;
    // }

    if (sectionObj != null && Object.keys(sectionObj).length > 0 && sectionObj['upload_image_content']['approval_status'] == 'approved'){
        // const url = makeImageUrl(sectionObj['image_url']);
        return <ImagePreview dataObject={sectionObj['upload_image_content']} />
    } else {
        return <></>;
    }
}

export default UserInputImage;