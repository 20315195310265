import { useState, useEffect } from 'react';
import ReportContentEditor from 'dashboard/reports/create_report_screen/report_content/ReportContentEditor';

function EditReportContent({ reportId }){

    const [existingContent, setExistingContent] = useState({});
    
    useEffect(() => {
        fetchContent();
    }, [reportId]);

    const fetchContent = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/${reportId}/content`, {
            method:'GET',
            headers:{
                Authorization:token
            }
        }).then(r => r.json())
        .then(r => {
            if(r.success==true){
                setExistingContent(r.data['content']);
            } else {
                alert('Edit Fetching Content');
            }
        })
    }

    return(
        <div>
            <ReportContentEditor reportId={reportId} defaultContent={existingContent} />
        </div>
    )
}

export default EditReportContent;