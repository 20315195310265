import Button from '@mui/material/Button';

function SendReportBtn({ reportId }){

    const sendReport = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/${reportId}/send`, {
            method:'POST',
            headers:{
                Authorization:token
            }
        }).then(r => r.json())
        .then(r => {
            if(r.success==true){
                alert('Report sent successfully');
            } else {
                alert('Error sending Report');
            }
        })
    }

    return (
        <Button variant='contained' onClick={() => sendReport()}>Send</Button>
    )

}

export default SendReportBtn;