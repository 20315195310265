import TablePreview from 'components/tables/TablePreview';

function UserInputTable({ sectionObj }){

    if (sectionObj != null && Object.keys(sectionObj).length > 0 && sectionObj['upload_excel_content']['approval_status'] == 'approved'){
        return <TablePreview 
            dataObject={sectionObj['upload_excel_content']} 
            editFlag={false} 
            breakPoint={sectionObj['upload_excel_content']['table_breakpoint']} 
        />
    } else {
        return <></>;
    }
}

export default UserInputTable;