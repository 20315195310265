import { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Header from "dashboard/sections/header/Header";
import Footer from "dashboard/sections/footer/Footer";
import PrimarySidebar from "dashboard/sections/sidebar/PrimarySidebar";
import "./dashboard.css";

function Dashboard() {

  const location = useLocation();
  const navigate = useNavigate();
  const [collapseSidebar, setCollapseSidebar] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", { state: `${location.pathname}${location.search}` });
    }
  }, []);

  const toggleSidebar = () => {
    const newState = !collapseSidebar;
    setCollapseSidebar(newState);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div>
          <Header toggleSidebar={toggleSidebar} />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <PrimarySidebar collapseSidebar={collapseSidebar} className="do-not-print" />
          </Grid>
          <Grid item xs={10}>
            <div style={{padding:'1vh'}}>
            <Outlet />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  )
}

export default Dashboard;
