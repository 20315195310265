/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";

import StepDefinition from "./StepDefinition";

const defaultSteps = [
  { section_name: "Executive Summary", section_code:'executive_summary', section_id:1 },
  { section_name: "Domestic News", section_code:'domestic_market_news', section_id:2},
  { section_name: "International News", section_code:'international_market_news', section_id:3},
  { section_name: "NCDEX Spot", section_code:'ncdex_spot', section_id:4},
  { section_name: "Spot Prices", section_code:'spot_prices', section_id:5},
];


function VerticalProgressBar({
  steps = defaultSteps,
  passStageInfo = (step) => {},
  finishProgress = () => {},
}) {

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    const nextActiveStep = activeStep + 1;
    handleClick(nextActiveStep);
  };

  const handleBack = () => {
    if (activeStep !== 0) {
      const prevActiveStep = activeStep - 1;
      handleClick(prevActiveStep);
    }
  };

  const handleClick = (stepIndex) => {
    if (stepIndex >= 0 && stepIndex < steps.length){
      setActiveStep(stepIndex);
      passStageInfo(stepIndex);
    } else {
      alert('Invalid Step Index, Error');
    }
  }

  return (
    <div>
      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={uuidv4()}>
              <StepDefinition
                stepIndex = {index}
                stepsLength={steps.length}
                stepLabel={step.section_name}
                stepContent={step.section_code}
                handleBack={handleBack}
                handleNext={handleNext}
                handleClick={handleClick}
              />
            </Step>
          ))}
        </Stepper>
        <br></br>
        {activeStep == (steps.length-1) && (
          // <StepCompleteMsg handleReset={handleReset} submit={finishProgress} />
          <Button variant="contained" onClick={() => finishProgress()}> SUBMIT REPORT</Button>
        )}
      </Box>
    </div>
  );
}

export default VerticalProgressBar;
