import { useState } from 'react';
import Table from '@mui/material/Table';
import { v4 as uuidv4 } from "uuid";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import EditReportModal from './EditReportModal';

function ArchiveTable({ reportList }) {

    const [reportDetails, setReportDetails] = useState(() => {
        if(reportList.length > 0){
            return reportList[0];
        }
        return {report_id:0, medium:'', commodity_name:'', commodity_id:'', report_type:'', publish_date:new Date()}
    });
    const [operation, setOperation] = useState('edit');
    const [modalStatus, setModalStatus] = useState(false);

    const openEditModal = (index) => {
        const selected = reportList[index];
        setOperation('edit')
        setReportDetails(selected);
        setModalStatus(true);
    }

    const handleClose = () => {
        setModalStatus(false);
        setOperation('edit');
    }

    const viewPreview = (index) => {
        const selected = reportList[index];
        setOperation('preview');
        setReportDetails(selected);
        setModalStatus(true);
    }

    const viewReportOperations = (index) => {
        const selected = reportList[index];
        setOperation('viewReportOperations');
        setReportDetails(selected);
        setModalStatus(true);
    }

    const viewLogs = (index) => {
        const selected = reportList[index];
        setOperation('viewLogs');
        setReportDetails(selected);
        setModalStatus(true);
    }

    // const sendReport = (index) => {
    //     const selected = reportList[index];
    //     const reportId = selected['report_id'];
    //     const token = localStorage.getItem('token');
    //     fetch(`${process.env.REACT_APP_SERVER_URL}/report/${reportId}/send`, {
    //         method:'POST',
    //         headers:{
    //             Authorization:token
    //         }
    //     }).then(r => r.json())
    //     .then(r => {
    //         if(r.success==true){
    //             alert('Report sent successfully');
    //         } else {
    //             alert('Error sending Report');
    //         }
    //     })
    // }

    return (
        <div>
                <TableContainer  component={Paper}> 
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Report ID (Medium)</TableCell>
                            <TableCell align="left">Report Type</TableCell>
                            <TableCell align="left">Publish Date</TableCell>
                            <TableCell align="center">Edit</TableCell>
                            <TableCell align="center">Preview</TableCell>
                            <TableCell align="center">Operations</TableCell>
                            <TableCell align="center">Email Logs</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportList.map((report, index) => (
                            <TableRow key={uuidv4()}>
                                <TableCell>{report.report_id} - ({report.medium})</TableCell>
                                <TableCell>{report.report_type}</TableCell>
                                <TableCell>{moment(report.publish_date).format('YYYY-MM-DD')}</TableCell>
                                <TableCell>
                                    <Button variant='contained' onClick={() => openEditModal(index)}>Edit</Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant='contained' onClick={() => viewPreview(index)}>Preview</Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant='contained' onClick={() => viewReportOperations(index)}>Operations</Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant='contained' onClick={() => viewLogs(index)}>ViewLogs</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EditReportModal 
                modalStatus={modalStatus}
                operation={operation}
                reportDetails={reportDetails}
                handleClose = {handleClose}
            />
        </div>

    )
}

export default ArchiveTable;