import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './summary_table.css';

const sampleResponse = {
    "cbot": {
        "Soybean Meal": {
            "change": -3.5,
            "current": 347.9,
            "month": "Dec 2026"
        },
        "Soybean Oil": {
            "change": 1.26,
            "current": 55.93,
            "month": "Dec 2026"
        },
        "Soybeans": {
            "change": 4.75,
            "current": 1179.25,
            "month": "Nov 2026"
        }
    },
    "currency": {
        "Argentina": {
            "change": "",
            "current": ""
        },
        "Australia": {
            "change": 0.0,
            "current": 0.68
        },
        "Brazil": {
            "change": -0.01,
            "current": 4.79
        },
        "European Union": {
            "change": 0.0,
            "current": 1.12
        },
        "India": {
            "change": -0.06,
            "current": 82.04
        },
        "Japan": {
            "change": "",
            "current": ""
        },
        "Malaysia": {
            "change": "",
            "current": ""
        },
        "Ukraine": {
            "change": "",
            "current": ""
        }
    },
    "domestic": {
        "Soybean Meal": {
            "change": -29.5,
            "current": 4119.5
        },
        "Soybean Oil": {
            "change": 100.0,
            "current": 9500.0
        },
        "Soybeans": {
            "change": -15.0,
            "current": 5095.0
        }
    },
    "fob": {
        "Argentina (Up River)": {
            "change": 0.0,
            "current": 556.0
        },
        "Brazil (Paranagua)": {
            "change": 4.0,
            "current": 527.0
        },
        "US 2Y (Gulf)": {
            "change": -1.0,
            "current": 584.0
        }
    },
    "ncdex": {
        "SBMEALIDR": {
            "change": 300.0,
            "current": 46300.0
        },
        "SOYAMEAL": {
            "change": 250.0,
            "current": 42000.0
        },
        "SYBEANIDR": {
            "change": 25.5,
            "current": 5074.0
        },
        "SYOREF": {
            "change": 20.5,
            "current": 945.55
        }
    }
}


function SoybeanDailySummary({ dataObject = sampleResponse }) {

    console.log(JSON.stringify(dataObject));

    return (
        <TableContainer component={Paper} className='tableContainer' sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                    <TableRow className='table-header-tg'>
                        <TableCell><b>Particulars</b></TableCell>
                        <TableCell><b>Latest</b></TableCell>
                        <TableCell><b>+/- Change</b></TableCell>
                    </TableRow>
                    {/* <TableRow>
                        <TableCell><b>Indian Domestic Update: - (Indore) (INR/MT)</b></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Soybeans</TableCell>
                        <TableCell>{dataObject['domestic']['Soybeans']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Soybeans']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Soybean Meal</TableCell>
                        <TableCell>{dataObject['domestic']['Soybean Meal']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Soybean Meal']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Soybean Oil</TableCell>
                        <TableCell>{dataObject['domestic']['Soybean Oil']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Soybean Oil']['change']}</TableCell>
                    </TableRow> */}
                    <TableRow>
                        <TableCell><b>Indian Domestic Update: - (Indore) (INR/MT):</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Ref Soybean Oil</TableCell>
                        <TableCell>{dataObject['ncdex']['SYOREF']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['SYOREF']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Soybeans</TableCell>
                        <TableCell>{dataObject['ncdex']['SYBEANIDR']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['SYBEANIDR']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Soybean Meal</TableCell>
                        <TableCell>{dataObject['ncdex']['SOYAMEAL']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['SOYAMEAL']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Soybean Meal (Hypro)</TableCell>
                        <TableCell>{dataObject['ncdex']['SBMEALIDR']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['SBMEALIDR']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>FOB at Key Origins (USD/MT)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Argentina</TableCell>
                        <TableCell>{dataObject['fob']['Argentina (Up River)']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['Argentina (Up River)']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Brazil (Paranagua)</TableCell>
                        <TableCell>{dataObject['fob']['Brazil (Paranagua)']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['Brazil (Paranagua)']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>USA (Gulf)</TableCell>
                        <TableCell>{dataObject['fob']['US 2Y (Gulf)']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['US 2Y (Gulf)']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    {/* <TableRow>
                        <TableCell><b>Indian Domestic Update: - (Indore) (INR/MT): NCDEX</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Ref Soybean Oil</TableCell>
                        <TableCell>{dataObject['ncdex']['SYOREF']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['SYOREF']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Soybeans</TableCell>
                        <TableCell>{dataObject['ncdex']['SYBEANIDR']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['SYBEANIDR']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Soybean Meal</TableCell>
                        <TableCell>{dataObject['ncdex']['SOYAMEAL']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['SOYAMEAL']['change']}</TableCell>
                    </TableRow> 
                    <TableRow>
                        <TableCell>Soybean Meal (Hypro)</TableCell>
                        <TableCell>{dataObject['ncdex']['SBMEALIDR']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['SBMEALIDR']['change']}</TableCell>
                    </TableRow>
                    {/* <TableRow className='color-row'>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow> */}
                    <TableRow>
                        <TableCell><b>CBOT: Commodity (Contract)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Soybeans ({dataObject['cbot']['Soybeans']['month']}) (in cents/Bushels)</TableCell>
                        <TableCell>{dataObject['cbot']['Soybeans']['current']}</TableCell>
                        <TableCell>{dataObject['cbot']['Soybeans']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Soybean Meal ({dataObject['cbot']['Soybean Meal']['month']}) (in USD/ST)</TableCell>
                        <TableCell>{dataObject['cbot']['Soybean Meal']['current']}</TableCell>
                        <TableCell>{dataObject['cbot']['Soybean Meal']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Soybean Oil ({dataObject['cbot']['Soybean Oil']['month']}) (in cents/Pounds)</TableCell>
                        <TableCell>{dataObject['cbot']['Soybean Oil']['current']}</TableCell>
                        <TableCell>{dataObject['cbot']['Soybean Oil']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Currency Reference (Pair with USD)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>India (INR)</TableCell>
                        <TableCell>{dataObject['currency']['India']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['India']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>European Union (EURO)</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Japan (JPY)</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Argentina (ARS)</TableCell>
                        <TableCell>{dataObject['currency']['Argentina']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Argentina']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Australia (AUD)</TableCell>
                        <TableCell>{dataObject['currency']['Australia']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Australia']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Brazil (BRL)</TableCell>
                        <TableCell>{dataObject['currency']['Brazil']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Brazil']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ukraine (UAH)</TableCell>
                        <TableCell>{dataObject['currency']['Ukraine']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Ukraine']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Malaysia (MYR)</TableCell>
                        <TableCell>{dataObject['currency']['Malaysia']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Malaysia']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default SoybeanDailySummary;