import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import BlockStyleControls from './BlockStyleControls';
import InlineStyleControls from './InlineStyleControls';
import 'draft-js/dist/Draft.css';
import { Editor, EditorState, getDefaultKeyBinding, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 14,
        padding: 2,
    },
};

function emptyState() {
    let editorState = EditorState.createEmpty();
    let contentState = editorState.getCurrentContent();
    let rawState = convertToRaw(contentState);
    return rawState;
}

function DraftEditor({ defaultContent = () => emptyState(), submit = () => {} }) {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [saveFlag, setSaveFlag] = useState(false);

    useEffect(() => {
        if (saveFlag == true) {
            setSaveFlag(false);
        }
    }, [editorState, defaultContent]);

    useEffect(() => {
        let currentContent = {};
        if (defaultContent !== null && Object.keys(defaultContent).length > 0) {
            try {
                currentContent = convertFromRaw(defaultContent);
                setEditorState(EditorState.createWithContent(currentContent))
            } catch (err) {
                alert('Invalid Content Passed');
            }
        } else {
            setEditorState(EditorState.createEmpty());
        }
    }, [defaultContent]);

    const submitDraft = () => {
        var contentState = editorState.getCurrentContent();
        var rawState = convertToRaw(contentState);
        setSaveFlag(true);
        submit(rawState)
    }

    const clearData = () => {
        setEditorState(EditorState.createEmpty());
    }

    const onChange = (newState) => {
        setEditorState(newState);
    };

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
            return true;
        }
        return false;
    };

    const mapKeyToEditorCommand = (e) => {
        if (e.keyCode === 9) {
            const newEditorState = RichUtils.onTab(e, editorState, 4);
            if (newEditorState !== editorState) {
                onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    };

    const toggleBlockType = (blockType) => {
        onChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    const toggleInlineStyle = (inlineStyle) => {
        onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div className="RichEditor-root" style={{ margin: '1vh', padding: '2vh' }}>
                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={toggleBlockType}
                    />
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />
                    <div className={className}>
                        <Editor
                            blockStyleFn={getBlockStyle}
                            customStyleMap={styleMap}
                            editorState={editorState}
                            handleKeyCommand={handleKeyCommand}
                            keyBindingFn={mapKeyToEditorCommand}
                            onChange={onChange}
                            placeholder="Write an article..."
                            spellCheck={true}
                        />
                    </div>
                </div>
            </Grid>
            <Grid item xs={6}>
                <TwoParallelButtons label1='Submit Draft' label2='Clear Draft' action1={submitDraft} action2={clearData} />
            </Grid>
            <Grid item xs={6}>
                <div style={{ float: 'right' }}>
                    {(saveFlag) ? 'Saved' : 'Pending'}
                </div>
            </Grid>
        </Grid>

    );
}

export default DraftEditor;