import { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

function PageBreakdownManager({ sectionId, label='Section Page Break', defaultState=false, flag='entire_section'}){

    const [checked, setChecked] = useState(()=>{
        if(defaultState == true){
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        if(defaultState == true){
            setChecked(true);
        } else {
            setChecked(false);
        }
    }, [sectionId]);

    const handleChange = (event) => {
        if (event.target.checked == 1) {
            updatePageBreak(true);
          } else {
            updatePageBreak(false);
          }
    }

    const updatePageBreak = (status) => {
        const token = localStorage.getItem('token');
        const body = {}
        if (flag == 'entire_section'){
            body['page_break_flag'] = status;
        } else if(flag == 'vis_chart'){
            body['chart_page_break'] = status;
        } else if(flag == 'vis_table'){
            body['table_page_break'] = status;
        }
        
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}`,{
            method:'PUT',
            headers:{
                Authorization:token,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(body)
        }).then(r => r.json())
        .then(r => {
            if(r.success==true){
                alert('Page Break status updated');
                if(status == true){
                    setChecked(true);
                } else {
                    setChecked(false);
                }
            } else {
                alert('Unable to update page break status');
            }
        })
    }

    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label={label}
        />
    )
}

export default PageBreakdownManager;