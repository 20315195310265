import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './summary_table.css';

// const sampleResponse = {
//     ncdex: {
//         'Gondal': { current: 61500, change: 50 },
//         'Kota': { current: 61500, change: 50 }
//     },
//     domestic: {
//         'Guna_Badami': { current: 61500, change: 50 },
//         'Guna_Eagle': { current: 61500, change: 50 },
//         'Kota_Badami': { current: 61500, change: 50 },
//         'Kota_Eagle': { current: 61500, change: 50 },
//         'Ramganj_Badami': { current: 61500, change: 50 },
//         'Ramganj_Eagle': { current: 61500, change: 50 }
//     },
//     currency: {
//         'India': { current: 1100, change: 50 },
//         'United Kingdom': { current: 1100, change: 50 },
//         'European Union': { current: 1100, change: 50 },
//         'Japan': { current: 1100, change: 50 }
//     }
// }

const sampleResponse = {
    "currency": {
        "European Union": {
            "change": 0.0,
            "current": 1.09
        },
        "India": {
            "change": -0.11,
            "current": 83.07
        },
        "Japan": {
            "change": "",
            "current": ""
        },
        "United Kingdom": {
            "change": 0.0,
            "current": 1.27
        }
    },
    "domestic": {
        "Guna_Badami": {
            "change": 75.0,
            "current": 6575.0
        },
        "Guna_Eagle": {
            "change": 75.0,
            "current": 6975.0
        },
        "Kota_Badami": {
            "change": -50.0,
            "current": 6275.0
        },
        "Kota_Eagle": {
            "change": -50.0,
            "current": 6775.0
        },
        "Ramganj_Badami": {
            "change": 100.0,
            "current": 6325.0
        },
        "Ramganj_Eagle": {
            "change": 100.0,
            "current": 7025.0
        }
    },
    "ncdex": {
        "Gondal": {
            "change": -61.4,
            "current": 7399.0
        },
        "Kota": {
            "change": 14.7,
            "current": 7563.7
        }
    }
}

function CorianderDailySummary({ dataObject = sampleResponse }) {

    return (
        <TableContainer className='tableContainer' component={Paper} sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                    <TableRow className='table-header-tg'>
                        <TableCell><b>Particulars</b></TableCell>
                        <TableCell><b>Latest</b></TableCell>
                        <TableCell><b>+/- Change</b></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Spot Prices</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Gondal</TableCell>
                        <TableCell>{dataObject['ncdex']['Gondal']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['Gondal']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Kota</TableCell>
                        <TableCell>{dataObject['ncdex']['Kota']['current']}</TableCell>
                        <TableCell>{dataObject['ncdex']['Kota']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Mandi Prices</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Guna</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Badami</TableCell>
                        <TableCell>{dataObject['domestic']['Guna_Badami']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Guna_Badami']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Eagle</TableCell>
                        <TableCell>{dataObject['domestic']['Guna_Eagle']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Guna_Eagle']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Kota</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Badami</TableCell>
                        <TableCell>{dataObject['domestic']['Kota_Badami']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Kota_Badami']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Eagle</TableCell>
                        <TableCell>{dataObject['domestic']['Kota_Eagle']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Kota_Eagle']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Ramganj</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Badami</TableCell>
                        <TableCell>{dataObject['domestic']['Ramganj_Badami']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Ramganj_Badami']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Eagle</TableCell>
                        <TableCell>{dataObject['domestic']['Ramganj_Eagle']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Ramganj_Eagle']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Currency Reference (Pair with USD)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>India</TableCell>
                        <TableCell>{dataObject['currency']['India']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['India']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>United Kingdom</TableCell>
                        <TableCell>{dataObject['currency']['United Kingdom']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['United Kingdom']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>European Union</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Japan</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['change']}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default CorianderDailySummary;