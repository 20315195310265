import { RouterProvider } from "react-router-dom";
import { AuthContext } from './auth/AuthContext';
import { router } from "Routes";


function UserProviderAuth() {

    const login = async (user, cb) => {
        // await localStorage.setItem('email', user.email);
        await localStorage.setItem('token', user.token);
        cb();
    };

    const logout = async () => {
        // await localStorage.removeItem('email');
        await localStorage.removeItem('token');
        // window.location.href = '/dashboard/login';
    };

    return {
        login,
        logout
    };
}

function EntryPoint(){

    const authContextValue = UserProviderAuth();

    return (
        <AuthContext.Provider value={authContextValue}>
            <RouterProvider router={router} />
        </AuthContext.Provider>
    )
}

export default EntryPoint;