import React from 'react';
import { EditorState, convertFromRaw } from 'draft-js';
import { convertToHTML } from 'draft-convert';

function HTMLToReactElement({ html }) {
    return (
      <div dangerouslySetInnerHTML={{ __html: html }} />
    );
  }

export function getHtml(description){
  if(description == null){
    return <></>;
  } else {
    let currentContent = convertFromRaw(description);
    let editorState = EditorState.createWithContent(currentContent);
    let html1 = convertToHTML(editorState.getCurrentContent());
    const reactElem = <HTMLToReactElement html={html1} />
    return reactElem;
  }

}




