import UploadReportSection from "dashboard/reports/create_report_screen/physical_report/UploadReportSection";
import Grid from '@mui/material/Grid';
import EditReportContent from '../report_content/EditReportContent';

function EditPhysicalReport({ reportDetails }){

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                Change Report
            </Grid>
            <Grid item xs={12}>
                <EditReportContent reportId = {reportDetails['report_id']} />
            </Grid>
            <Grid item xs={12}>
                <UploadReportSection reportId={reportDetails['report_id']} />
            </Grid>
        </Grid>   
    )
}

export default EditPhysicalReport;