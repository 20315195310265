
import React, { useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';
import { Container, Card } from 'react-bootstrap';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';
import CheckboxWithLabelDate from './CheckboxWithLabelDate';
import { useEffect } from 'react';


function SampleElem({ reportType, subscriptionMap, manageStatus, manageExpiryDate }) {
    return (
        <Grid item xs={12}>
            <CheckboxWithLabelDate
                label={reportType}
                checkboxStatus={subscriptionMap[reportType]['status']}
                expiryDate={subscriptionMap[reportType]['expiry_date']}
                setCheckboxStatus={manageStatus}
                setExpiryDate={manageExpiryDate}
            />
        </Grid>
    )
}

function ClientSubscriptionForm({ clientId = 0 }) {

    const [subscriptionMap, setSubscriptionMap] = useState({});
    const [saveFlag, setSaveFlag] = useState(false);
    const createSubscriptionMap = (subList) => {
        const subMap = {};
        for (let i = 0; i < subList.length; i++) {
            const sub = subList[i];
            subMap[sub['report_type_name']] = {
                'status': sub['status'],
                'expiry_date': sub['expiry_date'],
                'report_type_name': sub['report_type_name'],
                'report_type_id': sub['report_type_id']
            }
        }
        return subMap;
    }

    const fetchClientSubscriptions = () => {
        if (clientId == 0) {
            alert('Client Not Present');
            return;
        }
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/client/subscriptions/list/${clientId}`, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    const subscribed = r.data['subscribed']
                    const unsubscribed = r.data['unsubscribed']
                    const subscribed_list = subscribed.map(sub => {
                        const t = {
                            report_type_name: sub['report_type_name'],
                            report_type_id: sub['report_type_id'],
                            status: sub['status'],
                            expiry_date: moment(sub['expiry_date']).format('YYYY-MM-DD')
                        }
                        return t;
                    })
                    const unsubscribed_list = unsubscribed.map(sub => {
                        const t = {
                            report_type_name: sub['report_type_name'],
                            report_type_id: sub['id'],
                            status: false,
                            expiry_date: null
                        }
                        return t
                    })
                    const subscriptionList = subscribed_list.concat(unsubscribed_list);
                    const subMap = createSubscriptionMap(subscriptionList);
                    setSubscriptionMap(subMap);
                } else {
                    alert(r.data);
                }
            })
    }

    const manageStatus = (reportType, status) => {
        const copy = { ...subscriptionMap }
        const current = copy[reportType]
        current['status'] = status
        let current_expiry = current['expiry_date']
        if (current_expiry == null && status == true) {
            let date = new Date();
            let nextYearDate = moment(date.setFullYear(date.getFullYear() + 1)).format('YYYY-MM-DD');
            current['expiry_date'] = nextYearDate;
        }
        setSubscriptionMap(copy);
    }

    useEffect(() => {
        if(saveFlag == true){
            setSaveFlag(false);
        }
    }, [subscriptionMap]);

    const manageExpiryDate = (reportType, expiryDate) => {
        const copy = { ...subscriptionMap }
        copy[reportType]['expiry_date'] = expiryDate;
        setSubscriptionMap(copy);
    }

    const saveSubscriptions = () => {
        const subs_1 = Object.keys(subscriptionMap).map(t => subscriptionMap[t]).filter(t => t['status'] == true)
        const body = {
            client_id: clientId,
            subscriptions: subs_1
        }
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/client/subscriptions/`, {
            method: 'POST',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    alert('Subscriptions Created');
                    setSaveFlag(true);
                } else {
                    alert('Subscriptions not created');
                }
            })
    }

    if (Object.keys(subscriptionMap).length > 0) {
        return (
            <Container>
                <Card>
                    <Card.Header>
                        <h3>Select Client Subscriptions</h3>
                    </Card.Header>
                    <Card.Body style={{ height: '60vh', overflow: 'scroll' }}>
                        <Grid container spacing={2}>
                            {
                                Object.keys(subscriptionMap).map((reportType) => (
                                    <SampleElem
                                        key={uuidv4()}
                                        reportType={reportType}
                                        subscriptionMap={subscriptionMap}
                                        manageExpiryDate={manageExpiryDate}
                                        manageStatus={manageStatus}
                                    />
                                ))
                            }
                        </Grid>
                    </Card.Body>
                    <Card.Footer>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <TwoParallelButtons
                                    label1='Submit'
                                    label2='Fetch Subscription'
                                    action1={saveSubscriptions}
                                    action2={fetchClientSubscriptions}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ float: 'right' }}>
                                    {(saveFlag) ? 'Saved' : 'Pending'}
                                </div>
                            </Grid>
                        </Grid>
                    </Card.Footer>
                </Card>
            </Container>
        );

    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button variant='contained' onClick={fetchClientSubscriptions}>Fetch Subscription</Button>
                </Grid>
            </Grid>
        );
    }

}

export default ClientSubscriptionForm;