import Grid from "@mui/material/Grid";
import SelectReportParams from "./SelectReportParams";

function InitReportSection({ setReportParams, resetForm = () => {}}) {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h4>Initiate Report Parameters:</h4>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={8}>
          <SelectReportParams submitParams={setReportParams} resetForm={resetForm} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InitReportSection;
