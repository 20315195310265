import { useState } from 'react';
import Grid from '@mui/material/Grid';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';
import { useEffect } from 'react';

function EditClientPassword({ clientId }){

    const [newPassword, setNewPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [saveFlag, setSavedFlag] = useState(false);

    const resetFields = () => {
        setNewPassword('');
        setVerifyPassword('');
    }

    useEffect(()=>{
        if(saveFlag == true){
            setSavedFlag(false);
        }
    },[newPassword, verifyPassword]);

    const submitNewPassword = () => {
        if (newPassword == '' || verifyPassword == ''){
            alert('Fields cannot be empty');
        }
        else if (newPassword !== verifyPassword) {
            alert('Password not matched!!');
        } else {
            const formData = new FormData();
            const token = localStorage.getItem('token');
            formData.append('new_password', newPassword);
            fetch(`${process.env.REACT_APP_SERVER_URL}/client/${clientId}/password`, {
                method:'PUT',
                headers:{
                    Authorization:token
                },
                body:formData,
            }).then(r => r.json())
            .then(r => {
                if(r.success == true){
                    alert('Password successfully changed');
                    setSavedFlag(true);
                } else {
                    alert('Password Update Failed');
                }
            })
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h3>Change Client Password</h3>
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={true} label='Client Password' currentValue={newPassword} handleInput={setNewPassword} />
            </Grid>
            <Grid item xs={6}>
                <TextFieldInput isRequired={true} label='Verify Password' currentValue={verifyPassword} handleInput={setVerifyPassword} />
            </Grid>
            <Grid item xs={6}>
                {/* <Button variant='contained' onClick={submitNewPassword}> Change Password </Button> */}
                <TwoParallelButtons label1='Change Password' label2='Clear' action1={submitNewPassword} action2={resetFields} />
            </Grid>
            <Grid item xs={6}>
                <div style={{float:'right'}}>
                    {(saveFlag) ? 'Saved' : 'Pending'}
                </div>
            </Grid>
        </Grid>
    )

}

export default EditClientPassword;
