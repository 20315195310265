import Table from '@mui/material/Table';
import { v4 as uuidv4 } from "uuid";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import moment from 'moment';

function SubscriptionTable({ subscriptionList }){

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Report Type Name</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Expiry Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subscriptionList.map((subscription) => (
                            <TableRow key={uuidv4()}>
                                <TableCell align="center">{subscription.report_type_name}</TableCell>
                                <TableCell align="center">{subscription.client_email}</TableCell>
                                <TableCell align="center">{subscription.status?'approved':'pending'}</TableCell>
                                <TableCell align="center">{moment(subscription.expiry_date).format('YYYY-MM-DD')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
        </Grid>
    )

}

export default SubscriptionTable;