import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './summary_table.css';

const sampleResponse = {
    domestic:{
        'Sangli':{current:61500, change:50},
        'Chhindwara':{current:61500, change:50},
        'Delhi':{current:61500, change:50},
        'Nizamabad':{current:85500, change:-1000},
        'Gulabbagh':{current:61500, change:50},
        'bagalkota':{current:56500, change:500},
    },
    cbot:[{
        month:'Dec-23',
        current:591,
        change:-24
    }, 
    {
        month:'Jan-24',
        current:591,
        change:-24
    }, 
    {
        month:'Feb-24',
        current:591,
        change:-24
    }, 
    {
        month:'Mar-24',
        current:591,
        change:-24
    }],
    fob:{
        'argentina':{current:1100, change:50},
        'brazil':{current:1200, change:50},
        'us_3yc':{current:1300, change:50}
    },
    currency:{
        'India':{current:1100, change:50},
        'United Kingdom':{current:1100, change:50},
        'European Union':{current:1100, change:50},
        'Japan':{current:1100, change:50}
    }
}

function MaizeDailySummary({ dataObject=sampleResponse }){

    return (
        <TableContainer className='tableContainer' component={Paper} sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                    <TableRow className='table-header-tg'>
                    <TableCell><b>Particulars</b></TableCell>
                    <TableCell><b>Latest</b></TableCell>
                    <TableCell><b>+/- Change</b></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Mandi Prices (INR/Tonne)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow >
                        <TableCell>Sangli</TableCell>
                        <TableCell>{dataObject['domestic']['Sangli']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Sangli']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Chhindwara</TableCell>
                        <TableCell>{dataObject['domestic']['Chhindwara']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Chhindwara']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Delhi</TableCell>
                        <TableCell>{dataObject['domestic']['Delhi']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Delhi']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Nizamabad</TableCell>
                        <TableCell>{dataObject['domestic']['Nizamabad']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Nizamabad']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Gulabbagh</TableCell>
                        <TableCell>{dataObject['domestic']['Gulabbagh']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Gulabbagh']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>International Prices</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>CBOT (cents/bushel)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>{dataObject['cbot'][0]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][0]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][0]['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{dataObject['cbot'][1]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][1]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][1]['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>{dataObject['cbot'][2]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][2]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][2]['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{dataObject['cbot'][3]['month']}</TableCell>
                        <TableCell>{dataObject['cbot'][3]['current']}</TableCell>
                        <TableCell>{dataObject['cbot'][3]['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>FOB (USD/Tonne)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Argentina</TableCell>
                        <TableCell>{dataObject['fob']['argentina']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['argentina']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Brazil</TableCell>
                        <TableCell>{dataObject['fob']['brazil']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['brazil']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>USA</TableCell>
                        <TableCell>{dataObject['fob']['us_3yc']['current']}</TableCell>
                        <TableCell>{dataObject['fob']['us_3yc']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Currency Reference (Pair with USD)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>India (INR)</TableCell>
                        <TableCell>{dataObject['currency']['India']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['India']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>United Kingdom (GBP)</TableCell>
                        <TableCell>{dataObject['currency']['United Kingdom']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['United Kingdom']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>European Union (EURO)</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['European Union']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Japan (JPY)</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Japan']['change']}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default MaizeDailySummary;