import Grid from '@mui/material/Grid';
import React, { useEffect, useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import './report_preview.css';
import { v4 as uuidv4 } from "uuid";
import Button from '@mui/material/Button';
import ReportSection from './section/ReportSection';
import ReportHeader from "./fixed_components/ReportHeader";
import Disclaimer from "./fixed_components/Disclaimer";

const PreviewComponent = React.forwardRef(({ report }, ref) => {

  return (
    <div ref={ref} className="printable-content" >
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <div className="header" style={{ top: '0', left: '0', right: '0'}}>
            <div style={{borderBottom:'2px solid black'}}>
            <ReportHeader reportType={report['params']['report_type']} publishDate={report['params']['publish_date']} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="content" style={{ marginTop: '6px', marginBottom: '60px', zIndex: '1' }}>
            <div className="content-inner" style={{ paddingLeft: '1vh', paddingRight: '1vh' }}>
              {report['section_list'].map((sectionObj) => {
                return <ReportSection className='report_container' sectionObj={sectionObj} key={uuidv4()} />;

              })}
            </div>
            <div style={{marginTop:'60px'}}>
              <Disclaimer />
            </div>
          </div>
          
        </Grid>

        <Grid item xs={12}>
        <div className="footer" style={{ color: 'red', bottom: '0', left: '0', right: '0', zIndex: '100',borderBottom:'2px solid black' }}>
        {/* <ReportHeader reportType={report['params']['report_type']} publishDate={report['params']['publish_date']} /> */}
        </div>
        </Grid>
      </Grid>
      
    </div>
  );
});

PreviewComponent.displayName = "PreviewComponent";

function ReportPreview({ reportId = 0 }) {

  const [report, setReport] = useState({ 'publish_date': '1970-1-1', 'report_type': 'TEST REPORT', 'section_list': [] });
  const componentRef = useRef();

  useEffect(() => {
    if (reportId !== 0) {
      fetchReport();
    }
  }, [reportId]);

  const fetchReport = () => {
    const token = localStorage.getItem('token');
    const url = `${process.env.REACT_APP_SERVER_URL}/report/level/${reportId}`
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: token
      }
    })
      .then(r => r.json())
      .then(r => {
        if (r.success === true) {
          setReport(r.data);
        } else {
          alert('Error Fetching Report')
        }
      })
  }
  if (report['section_list'].length > 0) {
    return (
      <div>
        <ReactToPrint
          trigger={() => <Button variant='contained'>Print</Button>}
          content={() => componentRef.current}
          pageStyle={() => `
    @page {
      size: A4; 
    }

    body {
      position: relative;
      height: 100%;
    }

    .header {
      position: fixed;
      height: 5vh; 
      z-index: 100;
      top: 0;
    }

    .footer {
      position: fixed;
      height: 5vh; 
      z-index: 100;
      top:95vh;
    }

    .content {
      position: relative;
      top: 6vh;
      z-index: 1;
    }  
    .color-row {
      background-color: #61b043 !important; /* Add your desired background color */
  }
  `}
        />
        <br />
        <br />
        <PreviewComponent ref={componentRef} report={report} />
      </div>
    );
  } else {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>WAITING FOR REPORT</h3>
        </Grid>
      </Grid>
    );
  }
}

export default ReportPreview;