import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import CheckboxWithLabel from "components/input/custom_inputs/CheckboxWithLabel";
import TwoParallelButtons from "components/input/custom_inputs/parallel_button/TwoParallelButton";

function ReportFactorCheckboxes({ checkboxList = [], removeLabel=()=>{}, saveFactors=()=>{} }) {

    const [checkboxGrpList, setCheckboxGrpList] = useState(checkboxList);
    const [selectAllLabel, setSelectAllLabel] = useState('Select All');
    const [statusMap, setStatusMap] = useState({});

    useEffect(() => {
        const sortedList = checkboxList.sort((a,b) => a.priority - b.priority)
        createStatusMap(sortedList);
        setCheckboxGrpList(sortedList);
    }, [checkboxList]);

    const createStatusMap = (checkboxList) => {
        const tempMap = {};
        let i = 0;
        const statusKeys = Object.keys(statusMap);
        for (i = 0; i < checkboxList.length; i = i + 1) {
            const label = checkboxList[i].section_name;
            if (statusKeys.includes(label)) {
                tempMap[label] = statusMap[label]
            } else {
                tempMap[label] = checkboxList[i].section_active;
            }
        }
        setStatusMap(tempMap);
    }

    const manageSelection = (label, checked) => {
        const tempMap = { ...statusMap };
        tempMap[label] = checked;
        setStatusMap(tempMap);
    }

    const submitFactors = () => {
        let i=0;
        const finalList = [];
        for(i=0;i<checkboxGrpList.length;i=i+1){
            const checkBox = checkboxGrpList[i];
            checkBox.section_active = statusMap[checkBox.section_name];
            finalList.push(checkBox);
        }
        saveFactors(finalList);
    }

    const allOps = () => {
        const tempMap = { ...statusMap };
        const keys = Object.keys(tempMap);
        if(keys.length > 0){
            let label = ''
            let sign = ''
            if (selectAllLabel == 'Select All'){
                sign = true;
                label = 'Deselect All';
            } else {
                sign = false;
                label = 'Select All';
            }
            for(let i=0;i<keys.length;i++){
                tempMap[keys[i]] = sign;
            }
            setStatusMap(tempMap);
            setSelectAllLabel(label);
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControl>
                    <FormGroup>
                        {checkboxGrpList.map((checkbox) => (
                            <CheckboxWithLabel
                                key={uuidv4()}
                                label={checkbox.section_name}
                                handleCheck={manageSelection}
                                checked_status={statusMap[checkbox.section_name]}
                                deleteCheckbox={removeLabel}
                                enableDelete={checkbox.section_delete_enable}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TwoParallelButtons label1={'Save Factors'} label2={selectAllLabel} action1={submitFactors} action2={allOps} />
            </Grid>
        </Grid>

    )
}

export default ReportFactorCheckboxes;