import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { getHtml } from 'utils/getHtmlFromDraft';
import { getSearchParams } from 'utils/PatternUtils';
import Grid from '@mui/material/Grid';
import { Card, CardHeader, CardContent } from '@mui/material';
import ReportPreviewMapper from 'dashboard/reports/preview/ReportPreviewMapper';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';

function ReportPreviewWrapper({ show, contentApiResponse }) {

    if (show == true) {
        return (
            <ReportPreviewMapper
                reportId={contentApiResponse['params']['report_id']}
                medium={contentApiResponse['params']['medium']}
            />
        )
    } else {
        return <></>;
    }
}

function ShowReport() {

    const urlParams = useLocation();
    const [show, setShow] = useState(false);
    const [contentApiResponse, setContentApiResponse] = useState({
        params: {
            'commodity_name': '',
            'report_type': '',
            'publish_date': '1970-1-1',
            'medium': '',
            'report_id': 0
        },
        content: null
    });

    useEffect(() => {
        fetchReportContent(); 
    }, []);
    
    const showReport = () => {
        setShow(true);
    }
    
    const hideReport = () => {
        setShow(false);
    }

    const fetchReportContent = () => {
        const searchParams = getSearchParams(urlParams);
        const token = localStorage.getItem('token');
        if(Object.keys(searchParams).includes("reportId")){
            fetch(`${process.env.REACT_APP_SERVER_URL}/report/${searchParams["reportId"]}/content`, {
                method: 'GET',
                headers: {
                    Authorization: token
                }
            }).then(r => r.json())
                .then(r => {
                    if (r.success == true) {
                        setContentApiResponse(r.data);
                    } else {
                        alert('Unable to Fetch Content')
                    }
                })
        } else {
            alert('Report Not Found');
        }
    }

    if (contentApiResponse['params']['report_id'] !== 0 && contentApiResponse['content'] != null) {
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader>
                            {contentApiResponse.params.report_type} - {contentApiResponse.params.publish_date}
                        </CardHeader>
                        <CardContent>
                            <h2>Quick Take Away for report : </h2>
                            <div>
                                {getHtml(contentApiResponse.content)}
                            </div>
                            <div>
                                <TwoParallelButtons
                                    label1='Show/Download Report'
                                    label2='Hide Report'
                                    action1={showReport}
                                    action2={hideReport}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <ReportPreviewWrapper show={show} contentApiResponse={contentApiResponse} />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    Waiting to fetch the report
                </Grid>
            </Grid>
        )
    }

}

export default ShowReport;