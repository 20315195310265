import { useState } from 'react';
import Table from '@mui/material/Table';
import { v4 as uuidv4 } from "uuid";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import EditClientModal from './EditClientModal';

function ClientTable({ clientList, reset }){

    const [clientDetails, setClientDetails] = useState(() => {
        if(clientList.length > 0){
            return clientList[0];
        }
        return {client_id:0}
    });
    const [operation, setOperation] = useState('edit');
    const [modalStatus, setModalStatus] = useState(false);

    const openModal = (index) => {
        const selected = clientList[index];
        setClientDetails(selected);
        setModalStatus(true);
    }

    const editPersonalInfoModal = (e, index) => {
        e.preventDefault();
        setOperation('editPersonal');
        openModal(index);
    }

    const editSubscriptionModal = (e, index) => {
        e.preventDefault();
        setOperation('editSubscription');
        openModal(index);
    }

    const openEmailHistoryModal = (e, index) => {
        e.preventDefault();
        setOperation('openEmailHistory');
        openModal(index);
    }

    const handleClose = () => {
        setModalStatus(false);
        setOperation('edit');
        reset();
    }

    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            {/* <TableCell>Contact</TableCell> */}
                            <TableCell>Organization</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Personal Info</TableCell>
                            <TableCell>Subscriptions</TableCell>
                            <TableCell>Email History</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clientList.map((client, index) => (
                            <TableRow key={uuidv4()}>
                                <TableCell>{client.name}</TableCell>
                                <TableCell>{client.email}</TableCell>
                                {/* <TableCell>{client.phone}</TableCell> */}
                                <TableCell>{client.organization}</TableCell>
                                <TableCell>{client.status}</TableCell>
                                <TableCell>
                                    <Button variant='contained' onClick={(e) => editPersonalInfoModal(e, index)}>Edit</Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant='contained' onClick={(e) => editSubscriptionModal(e, index)}>Manage</Button>
                                </TableCell>
                                <TableCell>
                                    <Button variant='contained' onClick={(e) => openEmailHistoryModal(e, index)}>View</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EditClientModal
                modalStatus={modalStatus}
                operation={operation}
                handleClose={handleClose}
                clientDetails = {clientDetails}
             />
        </div>
    )
}

export default ClientTable;