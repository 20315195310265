import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './summary_table.css';

const sampleResponse = {
    domestic:{
        'Bengal_Gram_Delhi':{current:61500, change:50},
        'Masoor_Kanpur':{current:61500, change:50},
        'Moong_Mertha_City':{current:61500, change:50},
        'Pea_Kanpur':{current:85500, change:-1000},
        'Tur_Akola':{current:61500, change:50},
        'Urad_Mumbai':{current:56500, change:500},
    },
    international:{
        'Tur_Lemon':{current:1100, change:50},
        'Urad_SQ':{current:1200, change:50},
        'Urad_FAQ':{current:1300, change:50},
        'Lentil_Crimson':{current:1400, change:-1000},
        'Lentil_Nipper':{current:1500, change:50},
    },
    currency:{
        'united_states':{current:1100, change:50},
        'Australia':{current:1100, change:50},
        'India':{current:83, change:0.1},
        'Canada':{current:1100, change:50},
        'Ukraine':{current:1100, change:50},
        'Ethopia':{current:1100, change:50},
        'Russia':{current:1100, change:50},
        'Mayanmar':{current:1100, change:50},
        'Malawi':{current:1100, change:50},
        'Tanzania':{current:1100, change:50}
    }
}

function PulsesDailySummary({ dataObject=sampleResponse}){

    return (
        <TableContainer  className='tableContainer' component={Paper} sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                    <TableRow className='table-header-tg'>
                        <TableCell><b>Particulars</b></TableCell>
                        <TableCell><b>Latest</b></TableCell>
                        <TableCell><b>+/- Change</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell><b>Mandi Prices (in INR/Tonne)</b></TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Chana (Delhi)</TableCell>
                        <TableCell>{dataObject['domestic']['Bengal_Gram_Delhi']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Bengal_Gram_Delhi']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Masur (Kanpur)</TableCell>
                        <TableCell>{dataObject['domestic']['Masoor_Kanpur']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Masoor_Kanpur']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Tur (Akola)</TableCell>
                        <TableCell>{dataObject['domestic']['Tur_Akola']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Tur_Akola']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Urad (Mumbai)</TableCell>
                        <TableCell>{dataObject['domestic']['Urad_Mumbai']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Urad_Mumbai']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Moong (Mertha city)</TableCell>
                        <TableCell>{dataObject['domestic']['Moong_Mertha_City']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Moong_Mertha_City']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Yellow Pea (Kanpur)</TableCell>
                        <TableCell>{dataObject['domestic']['Pea_Kanpur']['current']}</TableCell>
                        <TableCell>{dataObject['domestic']['Pea_Kanpur']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>International Pulses Price (in USD/Tonne)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Tur (Lemon)</TableCell>
                        <TableCell>{dataObject['international']['Tur_Lemon']['current']}</TableCell>
                        <TableCell>{dataObject['international']['Tur_Lemon']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Urad (SQ)</TableCell>
                        <TableCell>{dataObject['international']['Urad_SQ']['current']}</TableCell>
                        <TableCell>{dataObject['international']['Urad_SQ']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Urad (FAQ)</TableCell>
                        <TableCell>{dataObject['international']['Urad_FAQ']['current']}</TableCell>
                        <TableCell>{dataObject['international']['Urad_FAQ']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Lentil (Crimson)</TableCell>
                        <TableCell>{dataObject['international']['Lentil_Crimson']['current']}</TableCell>
                        <TableCell>{dataObject['international']['Lentil_Crimson']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Lentil (Nipper/Hallmark)</TableCell>
                        <TableCell>{dataObject['international']['Lentil_Nipper']['current']}</TableCell>
                        <TableCell>{dataObject['international']['Lentil_Nipper']['change']}</TableCell>
                    </TableRow>
                    <TableRow></TableRow>
                    <TableRow className='color-row'>
                        <TableCell><b>Currency Reference (Pair with USD)</b></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Indian Rupee</TableCell>
                        <TableCell>{dataObject['currency']['India']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['India']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Australian Dollar</TableCell>
                        <TableCell>{dataObject['currency']['Australia']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Australia']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Canadian Dollar</TableCell>
                        <TableCell>{dataObject['currency']['Canada']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Canada']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Ukrainian Hryvnia</TableCell>
                        <TableCell>{dataObject['currency']['Ukraine']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Ukraine']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Ethiopian Birr</TableCell>
                        <TableCell>{dataObject['currency']['Ethopia']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Ethopia']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Russian Ruble</TableCell>
                        <TableCell>{dataObject['currency']['Russia']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Russia']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Burmese Kyat</TableCell>
                        <TableCell>{dataObject['currency']['Mayanmar']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Mayanmar']['change']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Malawian Kwacha</TableCell>
                        <TableCell>{dataObject['currency']['Malawi']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Malawi']['change']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Tanzanian Shilling</TableCell>
                        <TableCell>{dataObject['currency']['Tanzania']['current']}</TableCell>
                        <TableCell>{dataObject['currency']['Tanzania']['change']}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default PulsesDailySummary;
