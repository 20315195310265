import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography"

function AlertDialogBox({ alertMsg, openFlag, handleClose }){

    return (
        <Dialog onClose={handleClose} open={openFlag}>
            <Typography>{alertMsg}</Typography>
            <p>This is a custom alert box. Please respect the warnings</p>
        </Dialog>
    )
}

export default AlertDialogBox;