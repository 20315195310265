import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { EditorState, convertToRaw } from "draft-js";

import DraftEditor from "components/input/raw_inputs/draft_editor/DraftEditor";

function emptyState() {
  let editorState = EditorState.createEmpty();
  let contentState = editorState.getCurrentContent();
  let rawState = convertToRaw(contentState);
  return rawState;
}

function DraftInputContainer({ sectionId, defaultContent }) {

  const [defaultContentState, setDefaultContentState] = useState(() => emptyState());
 
  useEffect(() => {
    if (defaultContent == null || Object.keys(defaultContent).length == 0) {
      setDefaultContentState(() => emptyState());
    } else {
      setDefaultContentState(defaultContent);
    }
  }, [sectionId, defaultContent]);

  const updateDraft = (draftContent) => {
    const authToken = localStorage.getItem('token');
    let requestBody = {
      visual_draft_content: draftContent,
    };
    fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify(requestBody),
    })
      .then(r => r.json())
      .then(res => {
        if (res.success == true) {
          alert(res.data);
        } else {
          alert(JSON.stringify(res.data));
        }
      });
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Enter Draft Details:{" "}
        </Grid>
        <Grid item xs={12}>
          <DraftEditor
            submit={updateDraft}
            defaultContent={defaultContentState}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default DraftInputContainer;
