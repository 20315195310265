import Grid from '@mui/material/Grid';
import DynamicDenseTable from 'components/tables/dense_table/DynamicDenseTable';

export default function CmeFuturesDailyTable(){
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <DynamicDenseTable />
            </Grid>
        </Grid>
    )
    
}