import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DynamicDenseTable from "./DynamicDenseTable";
import TextFieldInput from 'components/input/raw_inputs/TextFieldInput';

const defaulCoulumns = ['name', 'age', 'city', 'country'];

const defaultRows = [
    { name: 'Tushar', age: 28, city: 'jal', country: 'india' },
    { name: 'Akash', age: 30, city: 'LA', country: 'USA' }
];

function DynamicTableContainer({ columnHeaders = defaulCoulumns, columnRows = defaultRows, sectionId=0, tableType='vis_tabe', breakPointStr='100' }){

    const [breakPoint, setBreakPoint] = useState(breakPointStr);
    const [breakPointListStr, setBreakPointListStr] = useState(breakPointStr);

    useEffect(() => {
        setBreakPoint(breakPointStr);
        setBreakPointListStr(breakPointStr);
    }, [breakPointStr]);

    const saveBreakpoint = () => {
        const token = localStorage.getItem('token');
        const body = {}
        if(tableType == 'vis_table'){
            body['visual_table_breakpoint'] = breakPoint;
        } else if(tableType == 'upload_table'){
            body['upload_excel_breakpoint'] = breakPoint;
        }
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/automation/${sectionId}`, {
            method:'PUT',
            headers:{
                Authorization:token,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(body)
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                alert('Break Point saved');
                setBreakPointListStr(breakPoint);
            } else {
                alert('Unable to save Breakpoint');
            }
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <DynamicDenseTable columnHeaders={columnHeaders}  columnRows={columnRows} breakPointListStr={breakPointListStr} />
            </Grid>
            <Grid item xs={12}>
                <TextFieldInput label='Add Breakpoint' currentValue={breakPoint} handleInput={setBreakPoint} />
                <Button variant="contained" type="submit" onClick={() => saveBreakpoint()} >
                    Submit
                </Button>
            </Grid>
        </Grid>
    )

}

export default DynamicTableContainer;