import { v4 as uuidv4 } from "uuid";
import Grid from '@mui/material/Grid';
import BarLineComboPlot from "../visuals/BarLineComboPlot";

function MultipleBarLineCombo({ dataObject = {} }) {
    
    return (
        <Grid container>
            {
                Object.keys(dataObject).map((key) => (
                    <Grid item xs={12} key={uuidv4()}>
                        <BarLineComboPlot dataObject={dataObject[key]['info']['data']} metadata={dataObject[key]['metadata']} />
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default MultipleBarLineCombo;