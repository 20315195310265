// import DenseTable from "./dense_table/DenseTable";
import WheatDailySummary from "./summary_tables/WheatDailySummary";
import PulsesDailySummary from "./summary_tables/PulsesDailySummary";
import MaizeDailySummary from "./summary_tables/MaizeDailySummary";
import RiceDailySummary from "./summary_tables/RiceDailySummary";
import PulsesImportParityTable from "./calculation_table/import_parity/PulsesImportParityTable";
import WheatImportParityTableLatest from "./calculation_table/import_parity/WheatImportParityLatest";
import SoybeanDailySummary from "./summary_tables/SoybeanDailySummary";
import SoybeanCrushMarginTable from "./calculation_table/crush_margin/SoybeanCrushMarginTable";
import RapeseedCrushMarginTable from "./calculation_table/crush_margin/RapeseedCrushMarginTable";
import CorianderDailySummary from "./summary_tables/CorianderDailySummary";
import RapeseedDailySummary from "./summary_tables/RapeseedDailySummary";
import SoybeanImportParityTable from "./calculation_table/import_parity/SoybeanImportParityTable";
import DynamicTableContainer from "./dense_table/DynamicTableContainer";
import DynamicDenseTable from "./dense_table/DynamicDenseTable";

function TableMapper({ type, info={}, editFlag=true, sectionId=0, tableType='vis_table', breakPoint='100' }){

    if (type == 'simple_table'){
        if (editFlag){
            return <DynamicTableContainer
                  columnHeaders={info['headers']} 
                  columnRows={info['data']} 
                  sectionId={sectionId}
                  tableType={tableType}
                  breakPointStr={breakPoint}
                />
        } else {
            return <DynamicDenseTable columnHeaders={info['headers']} columnRows={info['data']} breakPointListStr={breakPoint} />
        }
    } else if(type == 'wheat_daily_summary'){
        return <WheatDailySummary dataObject={info['data']} />
    } else if(type == 'pulses_daily_summary'){
        return <PulsesDailySummary dataObject={info['data']} />
    } else if(type == 'maize_daily_summary'){
        return <MaizeDailySummary dataObject={info['data']} />
    } else if(type == 'rice_daily_summary'){
        return <RiceDailySummary dataObject={info['data']} />
    } else if(type == 'soybean_daily_summary'){
        return <SoybeanDailySummary dataObject={info['data']} />
    } else if(type == 'coriander_daily_summary'){
        return <CorianderDailySummary dataObject={info['data']} />
    } else if(type == 'import_parity_pulses'){
        return <PulsesImportParityTable dataObject={info['data']} />
    } else if(type =='import_parity_wheat'){
        return <WheatImportParityTableLatest dataObject={info['data']} />
    }  else if(type == 'import_parity_soybeans'){
        return <SoybeanImportParityTable dataObject={info['data']} />
    } else if(type =='soybean_crush_margin_table'){
        return <SoybeanCrushMarginTable dataObject={info['data']} />
    } else if(type =='rapeseed_crush_margin_table'){
        return <RapeseedCrushMarginTable dataObject={info['data']} />
    } else if(type =='rapeseed_daily_summary'){
        return <RapeseedDailySummary dataObject={info['data']} />
        // return <RapeseedDailySummary dataObject={info['data']} />
    }
    else {
        return <></>
    }
}

export default TableMapper;