import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import SendReportBtn from './SendReportBtn';
import Paper from '@mui/material/Paper';
import UploadMasterExcel from './UploadMasterExcel';
import UploadReportSection from 'dashboard/reports/create_report_screen/physical_report/UploadReportSection';

function ReportOperations({ reportId }){

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Operations</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Send Report</TableCell>
                                <TableCell><SendReportBtn reportId={reportId} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Add Report Pdf</TableCell>
                                <TableCell>
                                    <UploadReportSection reportId={reportId} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Upload Master Excel</TableCell>
                                <TableCell><UploadMasterExcel reportId={reportId} /></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}

export default ReportOperations;