// Edit Report Mapper
import ReportEmailLog from "./report_logs/ReportEmailLog";
import ReportOperations from "./report_operations/ReportOperations";
import EditDigitalReport from "./digital_report/EditDigitalReport";
import EditPhysicalReport from "./physical_report/EditPhysicalReport";
import ReportPreviewMapper from "dashboard/reports/preview/ReportPreviewMapper";

function EditReportMapper({ reportDetails, operation }){

    if (operation == 'edit'){
        if (reportDetails['medium'] == 'physical'){
            return <EditPhysicalReport reportDetails={reportDetails} />
        } else if (reportDetails['medium']=='digital'){
            return <EditDigitalReport reportDetails={reportDetails} />
        }
    } else if (operation == 'preview'){
        return <ReportPreviewMapper medium={reportDetails['medium']} reportId={reportDetails['report_id']} />
    } else if (operation == 'viewReportOperations') {
        return <ReportOperations reportId={reportDetails['report_id']} />
    } else if (operation == 'viewLogs'){
        return <ReportEmailLog reportId={reportDetails['report_id']} />
    } else {
        return <></>;
    }
}

export default EditReportMapper;