// Report Workflow Main JSX Model
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";

import FactorDetailScreen from "./factor_detail_screen/FactorDetailScreen";
import ReportProgressBar from "./report_progress_bar/ReportProgressBar";

function ReportWorkflow() {

  const location = useLocation();
  const initContext = location.state;
  const [stepList, ] = useState(initContext['section_list']);
  const [sectionId, setSectionId] = useState(initContext['section_list'][0]['section_id'])

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <FactorDetailScreen sectionId={sectionId} />
      </Grid>
      <Grid item xs={2}>
        <ReportProgressBar stepList={stepList} setSectionId={setSectionId} />
      </Grid>
    </Grid>
  );
}

export default ReportWorkflow;
