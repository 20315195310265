import { Line } from 'react-chartjs-2';

function getDefaultData(){
    return {
        aTag:[
            { label:'2023-1-1', value:10 },
            { label:'2023-1-1', value:20 },
            { label:'2023-1-1', value:9 }
        ],
        bTag:[
            { label:'2023-1-1', value:8 },
            { label:'2023-1-1', value:15 },
            { label:'2023-1-1', value:12 }
        ]
    }
}

function getOptions(data, title, xAxisLabel, yAxisLabel){
    const default_options = {
        plugins:{
            title:{
                display:true,
                text: title
            }
        },
        scales:{
            x:{
                title:{
                    display:true,
                    text:xAxisLabel,
                    font:{
                        size:12,
                        weight:'bold'
                    }
                },
                ticks:{
                    callback: function(value, index) {
                        return data[index]['label'];
                    },
                    display:true,
                    minRotation:30,
                },
                grid:{
                    display:false
                }
            },
            y:{
                title:{
                    display:true,
                    text:yAxisLabel,
                    font:{
                        size:10,
                        weight:'bold'
                    }
                },
                ticks:{
                    callback: function(value) {
                    return value;
                }},
                grid:{
                    display:false
                }
            }
        }
    }
    return default_options
}

function getValues(labels, dataList){
    const valueList = []
    const nullRequired = labels.length - dataList.length
    for (let i=0;i<nullRequired;i++){
        valueList.push(null)
    }
    for(let i=0;i<dataList.length;i++){
        valueList.push(dataList[i]['value']);
    }
    return valueList;
}

const sampleMetaData = {
    chart_title:'Sample Title',
    x_axis_label:'Month Range',
    y_axis_label:'Quantity Values'
}

function MultiLineTimeSeries({ dataObject = getDefaultData(), metadata=sampleMetaData}) {

    const lineDataObject = {
        labels: [],
        datasets: []
    }
    const keys = Object.keys(dataObject);
    if (keys.length > 0){
        const labels = dataObject[keys[0]].map(t => t['label']);
        lineDataObject['labels'] = labels;
        const aSets = keys.map((key) => {
            const aSet = {
                label: key,
                data: getValues(labels, dataObject[key]),
                fill: false,
            }
            return aSet;
        });
        lineDataObject['datasets'] = aSets;
        const options = getOptions(dataObject[keys[0]], metadata['chart_title'], metadata['x_axis_label'], metadata['y_axis_label']);
        return (
            <div className="line-chart">
                <Line data={lineDataObject} options={options} />
            </div>
        );
    } else {
        return <></>;
    }

    
    
}

export default MultiLineTimeSeries;