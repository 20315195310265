import Grid from '@mui/material/Grid';
import { Modal } from 'react-bootstrap';
import EditClientMapper from './EditClientMapper';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';

const default_client = {
    client_id:0,
    name:'Sample Name',
    email:'Sample Email',
    organization:'Sample Org',
    phone:'Sample Phone'
}

function EditClientModal({ modalStatus, handleClose, clientDetails=default_client, operation='editPersonal' }){

    const deleteClient = () => {
        alert('NOT DECIDED ON THIS FEATURE YET, BACKEND DONE');
    }

    return (
        <Modal show={modalStatus} onHide={handleClose} size='xl'>
            <Modal.Header>
                <h3>Client Details</h3>
            </Modal.Header>
            <Modal.Body>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <p><b>Client Name :</b> {clientDetails.name} </p>
                    </Grid>
                    <Grid item xs={3}>
                        <p><b>Client Email:</b> {clientDetails.email} </p>
                    </Grid>
                    <Grid item xs={3}>
                        <p><b>Organization :</b> {clientDetails.organization} </p>
                    </Grid>
                    <Grid item xs={3}>
                        <p><b>Phone Number :</b> {clientDetails.phone} </p>             
                    </Grid>
                    <Grid item xs={12}>
                        <EditClientMapper operation={operation} clientDetails={clientDetails} />
                    </Grid>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
                <div style={{ float: 'right' }}>
                    <TwoParallelButtons label1='Close' label2='Delete Client' action1={handleClose} action2={deleteClient} />
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default EditClientModal;