import { useState } from 'react';
import Grid from '@mui/material/Grid';

import DateRangePickerInput from 'components/input/raw_inputs/DateRangePickerInput';
import PaginationControlled from 'components/input/raw_inputs/PaginationControlled';
import SelectInput from "components/input/raw_inputs/SelectInput";
import CommodityReportTypeSelector from 'dashboard/reports/common/CommodityReportTypeSelector';
import TwoParallelButtons from 'components/input/custom_inputs/parallel_button/TwoParallelButton';

function ArchiveFilter({ maxPageCount=10, setFilter = () => { } }) {

    const [commodity, setCommodity] = useState('');
    const [reportType, setReportType] = useState('');
    const [medium, setMedium] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    const [pageNumber, setPageNumber] = useState(1);

    const submitFilter = () => {
        const obj = {
            commodity_name: commodity,
            report_type: reportType,
            start_date: startDate,
            medium: medium,
            end_date: endDate,
            page_number: pageNumber
        };
        setFilter(obj);
    }

    const clearFilter = () => {
        setCommodity('');
        setReportType('');
        setStartDate('');
        setEndDate(new Date());
        setPageNumber(1);
        const obj = {
            commodity_name: '',
            report_type: '',
            start_date: '',
            medium: '',
            end_date: endDate,
            page_number: 1
        };
        setFilter(obj);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CommodityReportTypeSelector commodityName={commodity} setCommodity={setCommodity} reportType={reportType} setReportType={setReportType} />
            </Grid>
            <Grid item xs={12}>
                <SelectInput
                    label="Medium"
                    optionList={[{ label: 'Physical', value: 'physical' }, { label: 'Digital', value: 'digital' }]}
                    helperText="*Mandatory"
                    handleInput={setMedium}
                    value={medium}
                />
            </Grid>
            <Grid item xs={12}>
                <DateRangePickerInput
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                />
            </Grid>
            <Grid item xs={12}>
                <PaginationControlled selectedPage={pageNumber} pageCount={maxPageCount} setPage={setPageNumber} />
            </Grid>
            <Grid>
                <TwoParallelButtons label1='Submit' label2='Clear' action1={submitFilter} action2={clearFilter} />
            </Grid>
        </Grid>
    )

}

export default ArchiveFilter;