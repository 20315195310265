import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { sampleData } from './test';
import moment from 'moment';

function getOptions(chartTitle, xAxisLabel, yAxisLabel){
    const default_options = {
        plugins:{
            title:{
                display:true,
                text: chartTitle,
                font:{
                    size:12,
                    weight:'bold'
                }
            }
        },
        scales:{
            x:{
                title:{
                    display:true,
                    text:xAxisLabel,
                    font:{
                        size:10,
                        weight:'bold'
                    }
                },
                grid:{
                    display:false
                },
                ticks:{
                    callback: function(val) {
                        const label = moment(this.getLabelForValue(val), 'DD-BB-YYYY')
                        if ([1].includes(label.date())){
                            return this.getLabelForValue(val)
                        } else {
                            return ''
                        }
                    },
                    autoSkip:false,
                    minRotation:30,
                    maxRotation:30
                }
            },
            y:{
                title:{
                    display:true,
                    text:yAxisLabel,
                    font:{
                        size:12,
                        weight:'bold'
                    }
                },
                grid:{
                    display:false
                },
                ticks:{
                    stepSize:2,
                }
            }
        }
    }
    return default_options
}

const sampleMetaData = {
    chart_title:'Sample Title',
    x_axis_label:'Date Range',
    y_axis_label:'Index Values'
}

function SingleTimeSeriesMonthlyAxis({ dataObject = sampleData, metadata=sampleMetaData }){

    const [transformedObject, ] = useState(() => {
        const labels = dataObject.map((val) => val['label']);
        const values = dataObject.map((val) => val['value'])
        const object = {
            labels: labels,
            datasets: [
                {
                    label: metadata['chart_title'],
                    data: values,
                    fill: false,
                    borderColor: 'blue',
                },
            ],
        }
        return object;
    });
    const [options, ] = useState(() => {
        const opt = getOptions(metadata['chart_title'], metadata['x_axis_label'], metadata['y_axis_label']);
        return opt
    })

    return (
        <div className="line-chart">
            <Line data={transformedObject} options={options} />
        </div>
    );

}

export default SingleTimeSeriesMonthlyAxis;