import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './import_parity_table.css';

const sampleResponse = {
    'shipment_month':{period1:'1', period2:'', period3:''},
    'cbot_contract':{period1:'', period2:'2', period3:''},
    'cbot_value':{period1:'3', period2:'', period3:''},
    'premium':{period1:'', period2:'', period3:'4'},
    'total_cost':{period1:'', period2:'', period3:''},
    'fob_usdmt':{period1:'', period2:'9', period3:''},
    'bogies':{period1:'', period2:'', period3:''},
    'wci_cnf':{period1:'10', period2:'', period3:''},
    'exchange_rate':{period1:'', period2:'', period3:''},
    'landed_price':{period1:'', period2:'12', period3:''},
    'current_duty':{period1:'13', period2:'', period3:''},
    'port_expenses':{period1:'', period2:'14', period3:''},
    'duty_paid':{period1:'', period2:'', period3:'15'},
    'freight_plus_refining':{period1:'16', period2:'', period3:''},
    'refine_cost':{period1:'', period2:'17', period3:''}
}


function SoybeanImportParityTable({ dataObject=sampleResponse }) {

    return (
        <TableContainer component={Paper} className='tableContainer' sx={{ minWidth: 450 }}>
            <Table sx={{ minWidth: 450 }} size="small" stickyHeader aria-label="a dense table">
                <TableBody>
                    <TableRow className='table-header-tg'>
                        <TableCell>Shipment Month</TableCell>
                        <TableCell>{dataObject['shipment_month']['period1']}</TableCell>
                        <TableCell>{dataObject['shipment_month']['period2']}</TableCell>
                        <TableCell>{dataObject['shipment_month']['period3']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Applicable CBOT Contract</TableCell>
                        <TableCell>{dataObject['cbot_contract']['period1']}</TableCell>
                        <TableCell>{dataObject['cbot_contract']['period2']}</TableCell>
                        <TableCell>{dataObject['cbot_contract']['period3']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>CBOT Value (Cents/lb)</TableCell>
                        <TableCell>{dataObject['cbot_value']['period1']}</TableCell>
                        <TableCell>{dataObject['cbot_value']['period2']}</TableCell>
                        <TableCell>{dataObject['cbot_value']['period3']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Arg. Premium (Cents/lb)</TableCell>
                        <TableCell>{dataObject['premium']['period1']}</TableCell>
                        <TableCell>{dataObject['premium']['period2']}</TableCell>
                        <TableCell>{dataObject['premium']['period3']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total (Cents/lb)</TableCell>
                        <TableCell>{dataObject['total_cost']['period1']}</TableCell>
                        <TableCell>{dataObject['total_cost']['period2']}</TableCell>
                        <TableCell>{dataObject['total_cost']['period3']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>FOB (USD/Tonne)</TableCell>
                        <TableCell>{dataObject['fob_usdmt']['period1']}</TableCell>
                        <TableCell>{dataObject['fob_usdmt']['period2']}</TableCell>
                        <TableCell>{dataObject['fob_usdmt']['period3']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Bogies</TableCell>
                        <TableCell>{dataObject['bogies']['period1']}</TableCell>
                        <TableCell>{dataObject['bogies']['period2']}</TableCell>
                        <TableCell>{dataObject['bogies']['period3']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>WCI CNF (USD/Tonne)</TableCell>
                        <TableCell>{dataObject['wci_cnf']['period1']}</TableCell>
                        <TableCell>{dataObject['wci_cnf']['period2']}</TableCell>
                        <TableCell>{dataObject['wci_cnf']['period3']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>USD/INR Pair</TableCell>
                        <TableCell>{dataObject['exchange_rate']['period1']}</TableCell>
                        <TableCell>{dataObject['exchange_rate']['period2']}</TableCell>
                        <TableCell>{dataObject['exchange_rate']['period3']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Landed (INR/Tonne)</TableCell>
                        <TableCell>{dataObject['landed_price']['period1']}</TableCell>
                        <TableCell>{dataObject['landed_price']['period2']}</TableCell>
                        <TableCell>{dataObject['landed_price']['period3']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Current Duty (in INR)</TableCell>
                        <TableCell>{dataObject['current_duty']['period1']}</TableCell>
                        <TableCell>{dataObject['current_duty']['period2']}</TableCell>
                        <TableCell>{dataObject['current_duty']['period3']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Port Expenses</TableCell>
                        <TableCell>{dataObject['port_expenses']['period1']}</TableCell>
                        <TableCell>{dataObject['port_expenses']['period2']}</TableCell>
                        <TableCell>{dataObject['port_expenses']['period3']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Duty Paid (INR/10 KG)</TableCell>
                        <TableCell>{dataObject['duty_paid']['period1']}</TableCell>
                        <TableCell>{dataObject['duty_paid']['period2']}</TableCell>
                        <TableCell>{dataObject['duty_paid']['period3']}</TableCell>
                    </TableRow>
                    <TableRow className='color-row'>
                        <TableCell>Indore Freight + Refining</TableCell>
                        <TableCell>{dataObject['freight_plus_refining']['period1']}</TableCell>
                        <TableCell>{dataObject['freight_plus_refining']['period2']}</TableCell>
                        <TableCell>{dataObject['freight_plus_refining']['period3']}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Indore Refine Cost</TableCell>
                        <TableCell>{dataObject['refine_cost']['period1']}</TableCell>
                        <TableCell>{dataObject['refine_cost']['period2']}</TableCell>
                        <TableCell>{dataObject['refine_cost']['period3']}</TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default SoybeanImportParityTable;