import { Line } from "react-chartjs-2";
import React, { useState } from 'react';

const defaultData = {
        'seed_price': {
            title: 'Soybeans',
            data: [
                {
                    "label": "2021-09-11",
                    "value": 88250.0
                },
                {
                    "label": "2021-09-13",
                    "value": 87750.0
                },
                {
                    "label": "2021-09-14",
                    "value": 88000.0
                },
                {
                    "label": "2021-09-15",
                    "value": 87500.0
                },
            ],
            yAxisID: 'y'
        },
        'meal_price': {
            title: 'Soybeans Meal',
            data: [
                {
                    "label": "2021-09-11",
                    "value": 23000.0
                },
                {
                    "label": "2021-09-13",
                    "value": 22200.0
                },
                {
                    "label": "2021-09-14",
                    "value": 22200.0
                },
                {
                    "label": "2021-09-15",
                    "value": 22000.0
                }
            ],
            yAxisID: 'y'
        },
        'net_crush_margin': {
            title: 'Net Crush Margin',
            data: [
                {
                    "label": "2021-09-11",
                    "value": 6495.62
                },
                {
                    "label": "2021-09-13",
                    "value": 6508.38
                },
                {
                    "label": "2021-09-14",
                    "value": 6754.0
                },
                {
                    "label": "2021-09-15",
                    "value": 6388.75
                },
            ],
            yAxisID: 'y1'
        }
    }

const sampleMetaData = {
    chart_title: 'Sample Title',
    x_axis_label: 'Month Range',
    y_axis_label: 'Quantity Values',
    y1_axis_label: 'Y2 Axis Label'
}

function getOptions(chartTitle, xAxisLabel, yAxisLabel, y1AxisLabel) {
    const opt = {
        plugins: {
            title: {
                display: true,
                text: chartTitle,
                font: {
                    size: 20,
                    weight: 'bold'
                }
            }
        },
        scales: {
            y: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: yAxisLabel,
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                },
                grid: {
                    display: false,
                }
            },
            y1: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: y1AxisLabel,
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                },
                grid: {
                    display: false,
                },
                position:'right'
            },
            x: {
                title: {
                    display: true,
                    text: xAxisLabel,
                    font: {
                        size: 16,
                        weight: 'bold'
                    }
                },
                grid: {
                    display: false
                }
            }
        },
    };
    return opt;
}

function TwoAxesChart({ dataObject = defaultData, metadata = sampleMetaData }) {

    const [transformedObject,] = useState(() => {
        const plotDataObject = {
            labels: [],
            datasets: []
        }
        const keys = Object.keys(dataObject);
        let labels = []
        if (keys.length > 0) {
            labels = dataObject[keys[0]]['data'].map(t => t['label']);
            plotDataObject['labels'] = labels;
            const dSets = keys.map(key => {
                const obj = {
                    label: dataObject[key]['title'],
                    data: dataObject[key]['data'].map(t => t['value']),
                    borderWidth: 2,
                    yAxisID: dataObject[key]['yAxisID']
                }
                return obj
            });
            plotDataObject['datasets'] = dSets;
        }
        return plotDataObject;
    });

    const [options,] = useState(() => {
        const opt = getOptions(metadata['chart_title'], metadata['x_axis_label'], metadata['y_axis_label'], metadata['y1_axis_label']);
        return opt
    })

    if (transformedObject!== null && transformedObject['labels'].length > 0){
        return (
            <div className="line-chart">
                <Line data={transformedObject} options={options} />
            </div>
        );
    } else {
        return <></>;
    }
    
}

export default TwoAxesChart;