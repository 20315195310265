
function PageNotFound(){

    return (
        <h1>
            Page Not Found
        </h1>
    )
}

export default PageNotFound;