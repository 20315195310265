import { useState } from 'react';
import dayjs from 'dayjs';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ReportContentEditor from './report_content/ReportContentEditor';
import ReportMediumMapper from './ReportMediumMapper';
import InitReportSection from "./initiate_report/InitReportSection";
import AlertDialogBox from "components/input/custom_feedback/AlertDialogBox";

function CreateReportScreen() {

  const [warning, setWarning] = useState({ warningMsg: '', show: false });
  const [response, setResponse] = useState({ report_id: null, medium: null });
  const [msg, setMsg] = useState('');

  const closeDialogBox = () => {
    setWarning({ warningMsg: '', show: false });
  }

  const resetForm = () => {
    setMsg('');
    setResponse({ report_id: null, medium: null });
    setWarning({ warningMsg: '', show: false })
  }

  const setReportParams = (reportParams) => {
    const token = localStorage.getItem('token')
    const publishDate = dayjs(reportParams['publish_date']).format('YYYY-MM-DD')
    const formData = new FormData();
    formData.append('commodity_name', reportParams['commodity_name']);
    formData.append('report_type', reportParams['report_type']);
    formData.append('publish_date', publishDate);
    formData.append('medium', reportParams['medium']);
    fetch(`${process.env.REACT_APP_SERVER_URL}/report/`, {
      method: 'POST',
      headers: {
        'Authorization': token
      },
      body: formData
    })
      .then(r => r.json())
      .then(r => {
        if (r.success == true) {
          setResponse(r.data)
          alert(`New Report Initiated with Id : ${r.data['report_id']}`);
          setMsg(`New Report Initiated with Id : ${r.data['report_id']}`)
        } else {
          setWarning({warningMsg: r.data, show:true});
        }
      })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider>
          <h3>Initiate a New Report</h3>
        </Divider>
      </Grid>
      <Grid item xs={5}>
        <InitReportSection setReportParams={setReportParams} resetForm={resetForm} />
      </Grid>
      <Grid item xs={7}>
        <ReportContentEditor reportId={response['report_id']} defaultContent={{}} />
      </Grid>
      <Grid item xs={12}>
        <h5>{msg}</h5>
      </Grid>
      <Grid item xs={12}>
        <ReportMediumMapper reportId={response['report_id']} medium={response['medium']} />
      </Grid>
      <AlertDialogBox
        alertMsg={warning['warningMsg']}
        openFlag={warning['show']}
        handleClose={closeDialogBox}
      />
    </Grid>
  );
}

export default CreateReportScreen;
