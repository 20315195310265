import React from 'react';
import { Container } from 'react-bootstrap';
import './footer.css';

function Footer() {
    return (
        <Container fluid className='footer_container'>
            <p className='footer_content'>Copyright © 2022 Agnomics Analytics Labs Pvt. Ltd.</p>
        </Container>
    );
}

export default Footer;