import Grid from '@mui/material/Grid';
import { getHtml } from 'utils/getHtmlFromDraft';
import SectionVisualTable from './section_table/SectionVisualTable';
import SectionVisualChart from './section_chart/SectionVisualChart';
import UserInputTable from './section_table/UserInputTable';
import UserInputImage from './section_chart/UserInputImage';
import './sectionPreview.css';

function GetDraftContent({ sectionObj }) {
  if (sectionObj['visual_draft_content'] != null) {
    return getHtml(sectionObj['visual_draft_content']);
  }
}
function ReportSection({ sectionObj }) {
  return (
    <div className="printable-section">
      <Grid container spacing={0.1}>
        <Grid item xs={12}>
        <h3 style={{ fontFamily: 'Arial, sans-serif', fontSize: '20px', fontWeight: 'bold', textDecoration: 'underline' }}>
            {sectionObj.section_name}
          </h3>
        </Grid>
        <Grid item xs={12}>
          <h5 style={{ fontFamily: 'Arial, sans-serif', fontSize: '18px', fontWeight: 'bold' }}>
            {sectionObj.section_heading}
          </h5>
        </Grid>
        <Grid item xs={12}>
          <GetDraftContent sectionObj={sectionObj} />
        </Grid>
        <Grid item xs={12}>
          <SectionVisualChart sectionObj={sectionObj} spacing={2} />
        </Grid>
        <Grid item xs={12}>
          <SectionVisualTable sectionObj={sectionObj}/>
        </Grid>
        <Grid item xs={12}>
          <UserInputTable sectionObj={sectionObj} />
        </Grid>
        <Grid item xs={12}>
          <UserInputImage sectionObj={sectionObj} />
        </Grid>
      </Grid>
      {sectionObj['page_break_flag'] ? <div className="pagebreak" /> : <></>}
    </div>
  );
}
export default ReportSection;