import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import { v4 as uuidv4 } from "uuid";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import './report_email_logs.css';
import PaginationControlled from 'components/input/raw_inputs/PaginationControlled';

function ReportEmailLog({ reportId }){

    const [pageNumber, setPageNumber] = useState(1);
    const [emailLogList, setEmailLogList] = useState([]);

    const fetchReportLogs = () => {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_SERVER_URL}/report/${reportId}/emaillogs/${pageNumber}`,{
            headers:{
                Authorization:token,
            }
        }).then(r => r.json())
        .then(r => {
            if(r.success == true){
                setEmailLogList(r.data);
            } else {
                alert('Unable to fetch email logs');
            }
        })
    }

    useEffect(() => {
        fetchReportLogs();
    }, [reportId, pageNumber]);

    return (
        <div>
            <TableContainer component={Paper} className='div-scrollable'>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">MessageId</TableCell>
                            <TableCell align="center">Client Email</TableCell>
                            <TableCell align="center">Email Time</TableCell>
                            <TableCell align="center">Sender</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emailLogList.map((log) => (
                            <TableRow key={uuidv4()}>
                                <TableCell>{log.message_id}</TableCell>
                                <TableCell>{log.client_email}</TableCell>
                                <TableCell>{log.email_time}</TableCell>
                                <TableCell>{log.sender}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationControlled selectedPage={pageNumber} pageCount={10} setPage={setPageNumber} />
        </div>
    )

}

export default ReportEmailLog;