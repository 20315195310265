import TextField from "@mui/material/TextField";

function TextFieldInput({ isRequired = false, label = "Default Text Field", currentValue = '', helperText='', handleInput=()=>{} }) {

  const manageInput = (val) => {
    handleInput(val);
  }

  return (
    <div>
      <TextField
        required={isRequired}
        label={label}
        helperText={helperText}
        style={{width:'100%'}}
        value={currentValue}
        onChange={(e) => manageInput(e.target.value)}
        focused
      />
    </div>
  );
}

export default TextFieldInput;
