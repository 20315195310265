import {
  createRoutesFromElements,
  createBrowserRouter,
  Route,
} from "react-router-dom";
import Dashboard from "dashboard/Dashboard";
import Login from "auth/Login";
import PageNotFound from "components/error_pages/PageNotFound";
import DashboardIndex from "dashboard/DashboardIndex";
import { SampleRouter } from "dashboard/temp_samples/SampleRouter";
import { ReportsRouter } from "dashboard/reports/ReportsRouter";
import { CustomerRouter } from "dashboard/users/CustomerRouter";
import LogoutElem from 'auth/LogoutElem';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<PageNotFound />}>
      <Route index element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />}>
        <Route index element={<DashboardIndex />} />
        <Route path='/dashboard/logout' element={<LogoutElem />} />
        {SampleRouter}
        {ReportsRouter}
        {CustomerRouter}
      </Route>
      <Route path="/login" element={<Login />} />
    </Route>
  )
);
