import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


function PaginationControlled({ selectedPage, pageCount=10, setPage=() => {} }){

    const handleChange = (event, value) => {
        setPage(value);
    }

    return (
        <Stack spacing={2}>
            <Typography>Page: {selectedPage}</Typography>
            <Pagination count={pageCount} page={selectedPage} onChange={handleChange} />
        </Stack>
    )
    
}

export default PaginationControlled;