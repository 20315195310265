import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import UploadFileButton from "components/input/raw_inputs/UploadFileButton";

function UploadMasterExcel({ reportId }) {

    const [file, setFile] = useState(null);
    const [resetKey, setResetKey] = useState(0);
    const setFileFunc = (file) => {
        setFile(file);
    }

    useEffect(() => {
        setFile(null);
        setResetKey(0);
    }, [reportId]);

    const changeResetKey = () => {
        let t = resetKey;
        setResetKey(t + 1);
    }

    const uploadFile = (() => {
        if (file == null) {
            alert('Choose file first');
            return;
        }
        const authToken = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        const url = `${process.env.REACT_APP_SERVER_URL}/report/level/${reportId}/excel`;
        fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: authToken
            },
            body: formData
        })
            .then(r => r.json())
            .then(res => {
                if (res.success == true) {
                    alert('File uploaded successfully')
                } else if (res.success == false) {
                    alert(JSON.stringify(res.data));
                }
            });
        setFile(null);
        changeResetKey();
    });

    const downloadFile = () => {
        const authToken = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_URL}/report/level/${reportId}/excel`
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: authToken
            }
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    window.open(r.data);
                } else {
                    alert(r.data);
                }
            })
    }

    const deleteFile = () => {
        const authToken = localStorage.getItem('token');
        const url = `${process.env.REACT_APP_SERVER_URL}/report/level/${reportId}/excel`
        fetch(url, {
            method: 'DELETE',
            headers: {
                Authorization: authToken
            }
        }).then(r => r.json())
            .then(r => {
                if (r.success == true) {
                    alert('File Delete successfully');
                } else {
                    alert(r.data);
                }
            })
        changeResetKey();
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={5}>
                <UploadFileButton label='' fileTypeList={['.xlsx']} setFile={setFileFunc} resetKey={resetKey} />
            </Grid>
            <Grid item xs={7}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Button variant='contained' onClick={() => uploadFile()} >Upload File</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant='contained' onClick={() => downloadFile()}>Download File</Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant='contained' onClick={() => deleteFile()}>Delete File</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UploadMasterExcel;