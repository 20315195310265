import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import DatePickerInput from 'components/input/raw_inputs/DatePickerInput';

function CheckboxWithLabelDate({ label, expiryDate, setCheckboxStatus = () => { }, checkboxStatus = false, setExpiryDate = () => { } }) {

    const manageExpiryDate = (expiryDate) => {
        setExpiryDate(label, expiryDate);
    }

    const handleChange = (event) => {
        if (event.target.checked == 1) {
            setCheckboxStatus(label, true);
        } else {
            setCheckboxStatus(label, false);
        }
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox checked={checkboxStatus} onChange={handleChange} />}
                    label={label}
                />
            </Grid>
            <Grid item xs={6}>
                <DatePickerInput label='' value={expiryDate} setNewDate={manageExpiryDate} />
            </Grid>
        </Grid>
    )

}

export default CheckboxWithLabelDate;